import React, { useEffect } from "react";
import welogo from "../assets/images/welcome.png";
import { useNavigate } from "react-router";
const Welcome = () => {
  const navigete = useNavigate();
  useEffect(() => {
    const timer = setTimeout(() => {
      navigete("/home");
    }, 4000);
    // ?**Cleanup function to clear the timeout if the component unmounts or dependencies change
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div className="welcome">
        <img src={welogo} />
      </div>
    </>
  );
};

export default Welcome;
