import React, { useEffect, useState } from "react";
import inbanner from "../../assets/images/intro-banner.jpg";
import userImg from "../../assets/images/user.png";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import * as API from "../../API/Index.js";
import { BNOIMG, IMG, NOIMG } from "../../API/constant.js";
import { BeatLoader } from "react-spinners";
const Introdution = () => {
  const params = useParams();
  console.log("params", params);
  const location = useLocation();
  console.log("location", location);
  const [getUserData, setGetUserData] = useState("");
  const navigate = useNavigate();

  const userDataGetById = async () => {
    const header = localStorage.getItem("_tokenCode");
    try {
      const reqObj = {
        user_id: location.state.id,
      };

      const response = await API.getCreatorDataID(reqObj);
      console.log("response", response);
      setGetUserData(response.data.data);
      if (response.data.is_login === false) {
        localStorage.removeItem("_tokenCode");
        localStorage.removeItem("isLogin");
        if (localStorage.getItem("isLogin") === null) {
          navigate("/login");
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    userDataGetById();
  }, []);

  return (
    <>
      <section class="innerBanner pb-0">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-12 col-sm-11">
              <div class="innerBanner_img_wrap">
                <img
                  src={
                    getUserData.banner_img === "" ||
                    getUserData.banner_img === null
                      ? BNOIMG
                      : IMG + getUserData.banner_img
                  }
                  alt=""
                  class="w-100"
                />
              </div>
              <div class="users relative">
                <center>
                  <span class="main_User">
                    <img
                      src={
                        getUserData.profile_img === "" ||
                        getUserData.profile_img === null
                          ? NOIMG
                          : IMG + getUserData.profile_img
                      }
                      alt=""
                      class="w-100"
                    />
                    <span class="mail">
                      <i class="bi bi-envelope"></i>
                    </span>
                  </span>
                  <div class="clearfix"></div>
                  <span class="joined">Joined Jan 21</span>
                  <h3 class="user_heading">{getUserData.name}</h3>
                  <p class="user_text">{getUserData.bio}</p>
                  {/* <span>
                    <a href="#" class="btn btn-secondary2">
                      Follow
                    </a>
                  </span>
                  <span>
                    <a href="#" class="btn btn-outline-light">
                      Mention
                    </a>
                  </span> */}
                </center>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="tab_main">
        <div className="container">
          <div class="tab-content accordion" id="myTabContent">
            <div
              class="tab-pane fade show active accordion-item"
              id="introduction-pane"
              role="tabpanel"
              aria-labelledby="introduction-tab"
              tabindex="0"
            >
              <div
                id="collapseOne"
                class="accordion-collapse collapse show  d-lg-block"
                aria-labelledby="headingOne"
                data-bs-parent="#myTabContent"
              >
                <div class="accordion-body">
                  <h4 class="tab_heading">All Movie</h4>
                  <section class="movie_list p-0">
                    <div class="row">
                      <div class="col-sm-11">
                        <div class="row">
                          {getUserData.MovieList === undefined ||
                          getUserData.MovieList.length === 0 ? (
                            <div className="align-items-center d-flex justify-content-center">
                              No Movies Yet
                              {/* <BeatLoader size={20} color="#fff" /> */}
                            </div>
                          ) : (
                            getUserData.MovieList.map((item, index) => (
                              <div
                                class="col-sm-6 col-md-3 col-lg-2 movies"
                                key={index}
                              >
                                <Link
                                  state={{ dataMain: getUserData }}
                                  to={`/movie-details/${item.slug}`}
                                >
                                  <img
                                    src={
                                      item.poster === null
                                        ? NOIMG
                                        : IMG + item.poster
                                    }
                                    alt=""
                                    class="w-100"
                                  />
                                </Link>
                              </div>
                            ))
                          )}
                        </div>
                      </div>
                    </div>
                  </section>
                  <h4 class="tab_heading">Awards</h4>

                  <section class="movie_list p-0">
                    <div class="row">
                      <div class="col-sm-11">
                        <div class="row">
                          {getUserData.AwardList === undefined ||
                          getUserData.AwardList.length === 0 ? (
                            <div className="align-items-center d-flex justify-content-center">
                              No Awards Yet
                              {/* <BeatLoader size={20} color="#fff" /> */}
                            </div>
                          ) : (
                            getUserData.AwardList.map((item, index) => (
                              <div
                                class="col-sm-6 col-md-3 col-lg-2 movies"
                                key={index}
                              >
                                <Link state={{ dataMain: getUserData }} to="#">
                                  <img
                                    src={
                                      item.img === null ? NOIMG : IMG + item.img
                                    }
                                    alt=""
                                    class="w-100"
                                  />
                                </Link>
                                <h4 className="movieName">{item.title}</h4>
                              </div>
                            ))
                          )}
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Introdution;
