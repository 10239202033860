import logo from "./assets/images/logo.png";
import homeSlider from "./assets/images/movie.jpg";
import latestSlider from "./assets/images/release/1.jpg";
import movieTitle from "./assets/images/release/movie-ttle.png";
import topMovieSliders from "./assets/images/movies/1.jpg";
import superHeroesSlider from "./assets/images/hero/1.jpg";
import nGeographic from "./assets/images/ng.png";
import originalSlider from "./assets/images/original/1.jpg";
import video from "./assets/images/1.jpg";
import brands from "./assets/images/brand/Sponsor 1.png";
import appsOne from "./assets/images/1.jpg";
import appsTwo from "./assets/images/2.jpg";
import movieCasts from "./assets/images/movie-details/cast/1.jpg";
import movieCrew from "./assets/images/movie-details/crew/1.jpg";
import videoss from "./assets/images/videoplayback.mp4";
import fullscreen from "./assets/images/fullscreen.png";
import picture from "./assets/images/miniplayer.png";
import bannerMovie from "./assets/images/banner_list.jpg";
import poster from "./assets/images/poster.jpg";
import user from "./assets/images/user.jpg";
import homeBanner from "./assets/images/banner.jpg";
import homevideo from "./assets/images/videoplayback.mp4";
import iconReview from "./assets/images/review.png";
import iconFilm from "./assets/images/film.png";
import iconCalendar from "./assets/images/calendar.png";
import iconBlog from "./assets/images/blog.png";
import iconLink from "./assets/images/link.png";
import homeVideo from "./assets/images/videoplayback.mp4";

export const mainBanner = homeVideo;
export const commonVideo = homevideo;
export const homeBanners = homeBanner;
export const tailer = videoss;
export const fulScreenicon = fullscreen;
export const pipicon = picture;
export const logos = logo;
export const homeSliders = homeSlider;
export const latestSliders = latestSlider;
export const movieTitles = movieTitle;
export const topMovieSlider = topMovieSliders;
export const superHeroesSliders = superHeroesSlider;
export const nationalGeograhic = nGeographic;
export const original = originalSlider;
export const videos = video;
export const brand = brands;
export const appOne = appsOne;
export const appTwo = appsTwo;
export const casts = movieCasts;
export const crews = movieCrew;
export const reviewIcon = iconReview;
export const filmIcon = iconFilm;
export const calendarIcon = iconCalendar;
export const blogIcon = iconBlog;
export const linkIcon = iconLink;

export const bannerMovies = bannerMovie;
export const videoPoster = poster;
export const users = user;

export const formatTime = (time) => {
  //formarting duration of video
  if (isNaN(time)) {
    return "00:00";
  }

  const date = new Date(time * 1000);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds().toString().padStart(2, "0");
  if (hours) {
    //if video have hours
    return `${hours}:${minutes.toString().padStart(2, "0")} `;
  } else return `${minutes}:${seconds}`;
};

// ? MOVIE UPLOAD VALIDATION
export const validateForm = (data) => {
  let errors = [];

  for (let key in data) {
    switch (key) {
      case "movie_name":
        if (!data[key]) errors.push("Movie name is required");
        break;
      case "description":
        if (!data[key]) errors.push("Description is required");
        break;
      case "access":
        if (!data[key]) errors.push("Access is required");
        break;
      case "language":
        if (data[key].length === 0)
          errors.push("At least one language is required");
        break;
      case "genre":
        if (data[key].length === 0)
          errors.push("At least one genre is required");
        break;
      case "quality":
        if (data[key].length === 0)
          errors.push("Quality information is required");
        break;
      case "release_date":
        if (!data[key]) errors.push("Release date is required");
        break;
      case "cast":
        if (data[key].length === 0)
          errors.push("At least one cast member is required");
        break;
      case "crew":
        if (data[key].length === 0)
          errors.push("At least one crew member is required");
        break;
      case "certificate":
        if (data[key].length === 0)
          errors.push("Certificate information is required");
        break;
      case "thumbnail":
        if (!data[key]) errors.push("Thumbnail is required");
        break;
      case "poster":
        if (!data[key]) errors.push("Poster is required");
        break;
      case "user_id":
        if (!data[key]) errors.push("User ID is required");
        break;
      default:
        break;
    }
  }

  return errors;
};
