import React, { useEffect, useState } from "react";
import inbanner from "../../assets/images/intro-banner.jpg";
import userImg from "../../assets/images/user.png";
import ProfileSection from "./ProfileSection";
import { useLocation, useNavigate } from "react-router";
import * as API from "../../API/Index.js";
import VideoUpload from "./VideoUpload.js";
import { MESSAGE } from "../../schemas/Validation.js";
import Serise from "./Serise.js";
import { BeatLoader } from "react-spinners";
import { type } from "@testing-library/user-event/dist/type/index.js";
import MovieList from "./MyMovieList.js";
import SideBar from "../UserDashboard/SideBar.js";
const initialValues = {
  name: "",
  email: "",
  city: "",
  state: "",
  country: "",
  address: "",
  phone: "",
  user_name: "",
  profile_img: "",
  banner_img: "",
};
const Myaccount = ({ setIsLogin, data }) => {
  const navigate = useNavigate();
  const [isLoder, setIsLoder] = useState(false);
  const [getUserData, setGetUserData] = useState("");
  const [allCountryData, setAllCountryData] = useState([]);
  const [allStateData, setAllStateData] = useState([]);
  const [allCityData, setAllCityData] = useState([]);
  const [firstSerise, setFirstSerise] = useState(false);
  const [formData, setFormData] = useState(initialValues);
  const [allCartifi, setAllCartifi] = useState("");
  const [countryData, setCountryData] = useState("");
  const [stateData, setStateData] = useState("");
  const [cityData, setCityData] = useState("");
  const [profieData, setProfieData] = useState("");
  const [coverData, setCoverData] = useState("");
  const [isMovie, setIsMovie] = useState(0);

  const handalerChangespro = async (e) => {
    setProfieData(e.target.files[0]);
    setIsLoder(false);
  };
  const handalerChangescov = async (e) => {
    setCoverData(e.target.files[0]);
    setIsLoder(false);
  };

  const handalerChanges = async (e) => {
    setIsLoder(false);
    const { name, value } = e.target;
    const header = localStorage.getItem("_tokenCode");
    if (name === "country") {
      setCountryData(parseInt(e.target.value));
      const stateresponse = await API.allState(e.target.value, header);
      setAllStateData(stateresponse.data.data);
    }
    if (name === "state") {
      const reqObj = {
        catId: countryData,
        stateId: e.target.value,
      };
      console.log("reqObj", reqObj);
      setStateData(parseInt(e.target.value));
      const cityresponse = await API.allCity(reqObj, header);
      console.log("cityresponse", cityresponse);
      setAllCityData(cityresponse.data.data);
    }

    if (name === "city") {
      setCityData(parseInt(e.target.value));
    }

    setFormData({ ...formData, [name]: value });
  };

  const userdataUpdate = async () => {
    const header = localStorage.getItem("_tokenCode");
    setIsLoder(true);
    try {
      const formDataReq = new FormData();
      formDataReq.append("name", formData.name);
      formDataReq.append("user_name", formData.user_name);
      formDataReq.append("profile_img", profieData);
      formDataReq.append("banner_img", coverData);
      formDataReq.append(
        "city",
        typeof cityData === "number" ? cityData : formData.city
      );
      formDataReq.append("email", formData.email);
      formDataReq.append("role", formData.user_type);
      formDataReq.append(
        "state",
        typeof stateData === "number" ? stateData : formData.state
      );
      formDataReq.append(
        "country",
        typeof countryData === "number" ? countryData : formData.country
      );
      formDataReq.append("address", formData.address);
      formDataReq.append("phone", formData.age_limit);

      const response = await API.getuser_update(formDataReq, header);
      console.log("response", response);
      if (response.data.success === 1) {
        setIsLoder(false);

        MESSAGE(response.data.msg, 1);
      }
    } catch {}
  };

  const logout = () => {
    localStorage.removeItem("_tokenCode");
    localStorage.removeItem("isLogin");
    localStorage.removeItem("__userId");
    setIsLogin(localStorage.removeItem("isLogin"));
    if (localStorage.getItem("isLogin") === null) {
      navigate("/login");
    }
  };

  const pageshowhideSerise = () => {
    setFirstSerise(!firstSerise);
  };

  useEffect(() => {
    // userDataGetById();
  }, []);
  return (
    <>
      <ProfileSection formData={data} inbanner={inbanner} userImg={userImg} />
      <section>
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="jj">
              <div class="tab_main editProfile">
                <div class="row justify-content-center g-0">
                  <div class="col-sm-12 col-md-12 row">
                    <div className="col-md-3">
                      <SideBar
                        formData={formData}
                        inbanner={inbanner}
                        userImg={userImg}
                        setIsLogin={setIsLogin}
                      />
                    </div>

                    <div
                      class="tab-content accordion col-sm-9"
                      id="myTabContent"
                    >
                      <div
                        class="tab-pane fade show active"
                        id="introduction-pane"
                        role="tabpanel"
                        aria-labelledby="introduction-tab"
                        tabindex="0"
                      >
                        <h3>Dashboard</h3>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="series-pane"
                        role="tabpanel"
                        aria-labelledby="series-tab"
                        tabindex="0"
                      ></div>
                      <div
                        class="tab-pane fade"
                        id="yseries-pane"
                        role="tabpanel"
                        aria-labelledby="series-tab"
                        tabindex="0"
                      >
                        {/* <Serise
                          firstSerise={firstSerise}
                          getUserData={getUserData}
                        /> */}
                      </div>
                      <div
                        class="tab-pane fade"
                        id="award-pane"
                        role="tabpanel"
                        aria-labelledby="award-tab"
                        tabindex="0"
                      >
                        my award
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Myaccount;
