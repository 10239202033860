import React, { useEffect, useState } from 'react';
import * as API from "../../API/Index.js";
import { Accordion } from 'react-bootstrap-accordion';

const Faqs = () => {
  
  const [allFaqs, setAllFaqs]  = useState([])

  const allFaqsList = async()=>{
    try {

        const response = await API.getAllFaq()
        console.log("response",response);
        setAllFaqs(response.data.data)
    } catch (error) {
        
    }
  }

  useEffect(() =>{
    allFaqsList()
  },[])
    

  return (
    <>
        <section class="movie_list">
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-sm-11">
                        <div class="row justify-content-center">
                            <div class="col-md-8 faq">
                                <h2 class="text-center mb-5">Frequently Asked Questions</h2>

                                {/* <div class="accordion-item">
                                            <h2 class="accordion-header">
                                                <button class="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target={item.id} aria-expanded="false" aria-controls={item.id}>
                                                    {item.question}
                                                </button>
                                            </h2>
                                            <div id={item.id} class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    {item.answer}
                                                </div>
                                            </div>
                                        </div> */}

                                <div class="accordion" id="accordionExample">
                                    {allFaqs.map((item, index)=>(

                                <Accordion title={item.question}>
                              {item.answer}
                                </Accordion>
                                        
                                    ))}
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
}

export default Faqs;
