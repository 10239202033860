import React from 'react';
import { blogIcon, calendarIcon, filmIcon, linkIcon, reviewIcon } from '../../AppUtilities';
import { Link } from 'react-router-dom';

const ForumList = () => {
  return (
    <div>
      <section class="forums-page movie_list">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-3">
                    <div className='forumsSidbar'>
                        <ul class="forums-menu">
                            <li><Link to="#"><span><img src={reviewIcon} alt="icon" /></span>User Reviews</Link></li>
                            <li><Link to="#"><span><img src={filmIcon} alt="icon" /></span>Film Clubs</Link></li>
                            <li><Link to="#"><span><img src={calendarIcon} alt="icon" /></span>Events Calendar</Link>
                            </li>
                            <li><Link to="#"><span><img src={blogIcon} alt="icon" /></span>Blog</Link></li>
                            <li><Link to="#"><span><img src={linkIcon} alt="icon" /></span>Social Media Links</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="forums-tab tab_main secScroll">
                        <ul class="nav nav-tabs d-lg-flex" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                                    aria-selected="true">Recent Questions
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                                    aria-selected="false">Most Answered
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact"
                                    aria-selected="false">Answers
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="pills-visited-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-visited" type="button" role="tab" aria-controls="pills-contact"
                                    aria-selected="false">Most Visited

                                </button>
                            </li>
                        </ul>
                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                                aria-labelledby="pills-home-tab">
                                <div class="question-box">
                                    <Link to="/forum-details">
                                    <div class="question-inner">
                                        <div class="user-img">
                                            <img src="images/user.jpg" alt="user-img"
                                                    class="img-fluid" />
                                            <ul class="question-vote">
                                                <li class="question-vote-up"><i
                                                            class="fa-solid fa-caret-up"></i></li>
                                                <li class="vote_result">134</li>
                                                <li class="question-vote-down"><i
                                                            class="fa-solid fa-caret-down"></i></li>
                                            </ul>
                                        </div>

                                        <div class="question-content">
                                            <div class="question-header">
                                                <span class="user-name">James Wane</span>
                                                <span class="badge bg-danger">Enlightened</span>
                                                <div class="post">
                                                    <span class="post-date published">Asked: <span>April 19,
                                                            2023</span></span>
                                                    <span class="published">In:<span>Analytics</span></span>
                                                </div>

                                            </div>
                                            <h5>What are your thoughts on Google Analytics vs other analytics
                                                    platforms?</h5>
                                            <p class="question-wrp">Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                                Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                            </p>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="question-footer d-flex justify-content-between">
                                        <ul class="question-review">
                                            <li class="like"><i class="fa-solid fa-thumbs-up"></i></li>
                                            <li class="emoji">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span>172 Users</span>
                                            </li>
                                            <li class="comment-box">
                                                <i class="fa-solid fa-comment"></i>1
                                            </li>
                                            <li class="comment-box">
                                                <i class="fa-solid fa-comment"></i>2
                                            </li>
                                            <li>
                                                <i class="fa-regular fa-eye"></i>
                                                <span class="view">9k
                                                    Views</span>
                                            </li>
                                        </ul>
                                        <div class="vote">
                                            <span class="btn btn-secondary2">Answer</span>
                                        </div>
                                    </div>
                                    </Link>
                                </div>

                                <div class="question-box">
                                    <Link to="/forum-details">
                                    <div class="question-inner">
                                        <div class="user-img">
                                            <img src="images/user.jpg" alt="user-img"
                                                    class="img-fluid" />
                                            <ul class="question-vote">
                                                <li class="question-vote-up"><i
                                                            class="fa-solid fa-caret-up"></i></li>
                                                <li class="vote_result">134</li>
                                                <li class="question-vote-down"><i
                                                            class="fa-solid fa-caret-down"></i></li>
                                            </ul>
                                        </div>

                                        <div class="question-content">
                                            <div class="question-header">
                                                <span class="user-name">James Wane</span>
                                                <span class="badge bg-danger">Enlightened</span>
                                                <div class="post">
                                                    <span class="post-date published">Asked: <span>April 19,
                                                            2023</span></span>
                                                    <span class="published">In:<span>Analytics</span></span>
                                                </div>

                                            </div>
                                            <h5>What are your thoughts on Google Analytics vs other analytics
                                                    platforms?</h5>
                                            <p class="question-wrp">Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                                Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                            </p>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="question-footer d-flex justify-content-between">
                                        <ul class="question-review">
                                            <li class="like"><i class="fa-solid fa-thumbs-up"></i></li>
                                            <li class="emoji">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span>172 Users</span>
                                            </li>
                                            <li class="comment-box">
                                                <i class="fa-solid fa-comment"></i>1
                                            </li>
                                            <li class="comment-box">
                                                <i class="fa-solid fa-comment"></i>2
                                            </li>
                                            <li>
                                                <i class="fa-regular fa-eye"></i>
                                                <span class="view">9k
                                                    Views</span>
                                            </li>
                                        </ul>
                                        <div class="vote">
                                            <span class="btn btn-secondary2">Answer</span>
                                        </div>
                                    </div>
                                    </Link>
                                </div>

                                <div class="question-box">
                                    <Link to="/forum-details">
                                    <div class="question-inner">
                                        <div class="user-img">
                                            <img src="images/user.jpg" alt="user-img"
                                                    class="img-fluid" />
                                            <ul class="question-vote">
                                                <li class="question-vote-up"><i
                                                            class="fa-solid fa-caret-up"></i></li>
                                                <li class="vote_result">134</li>
                                                <li class="question-vote-down"><i
                                                            class="fa-solid fa-caret-down"></i></li>
                                            </ul>
                                        </div>

                                        <div class="question-content">
                                            <div class="question-header">
                                                <span class="user-name">James Wane</span>
                                                <span class="badge bg-danger">Enlightened</span>
                                                <div class="post">
                                                    <span class="post-date published">Asked: <span>April 19,
                                                            2023</span></span>
                                                    <span class="published">In:<span>Analytics</span></span>
                                                </div>

                                            </div>
                                            <h5>What are your thoughts on Google Analytics vs other analytics
                                                    platforms?</h5>
                                            <p class="question-wrp">Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                                Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                            </p>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="question-footer d-flex justify-content-between">
                                        <ul class="question-review">
                                            <li class="like"><i class="fa-solid fa-thumbs-up"></i></li>
                                            <li class="emoji">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span>172 Users</span>
                                            </li>
                                            <li class="comment-box">
                                                <i class="fa-solid fa-comment"></i>1
                                            </li>
                                            <li class="comment-box">
                                                <i class="fa-solid fa-comment"></i>2
                                            </li>
                                            <li>
                                                <i class="fa-regular fa-eye"></i>
                                                <span class="view">9k
                                                    Views</span>
                                            </li>
                                        </ul>
                                        <div class="vote">
                                            <span class="btn btn-secondary2">Answer</span>
                                        </div>
                                    </div>
                                    </Link>
                                </div>

                                <div class="question-box">
                                    <Link to="/forum-details">
                                    <div class="question-inner">
                                        <div class="user-img">
                                            <img src="images/user.jpg" alt="user-img"
                                                    class="img-fluid" />
                                            <ul class="question-vote">
                                                <li class="question-vote-up"><i
                                                            class="fa-solid fa-caret-up"></i></li>
                                                <li class="vote_result">134</li>
                                                <li class="question-vote-down"><i
                                                            class="fa-solid fa-caret-down"></i></li>
                                            </ul>
                                        </div>

                                        <div class="question-content">
                                            <div class="question-header">
                                                <span class="user-name">James Wane</span>
                                                <span class="badge bg-danger">Enlightened</span>
                                                <div class="post">
                                                    <span class="post-date published">Asked: <span>April 19,
                                                            2023</span></span>
                                                    <span class="published">In:<span>Analytics</span></span>
                                                </div>

                                            </div>
                                            <h5>What are your thoughts on Google Analytics vs other analytics
                                                    platforms?</h5>
                                            <p class="question-wrp">Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                                Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                            </p>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="question-footer d-flex justify-content-between">
                                        <ul class="question-review">
                                            <li class="like"><i class="fa-solid fa-thumbs-up"></i></li>
                                            <li class="emoji">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span>172 Users</span>
                                            </li>
                                            <li class="comment-box">
                                                <i class="fa-solid fa-comment"></i>1
                                            </li>
                                            <li class="comment-box">
                                                <i class="fa-solid fa-comment"></i>2
                                            </li>
                                            <li>
                                                <i class="fa-regular fa-eye"></i>
                                                <span class="view">9k
                                                    Views</span>
                                            </li>
                                        </ul>
                                        <div class="vote">
                                            <span class="btn btn-secondary2">Answer</span>
                                        </div>
                                    </div>
                                    </Link>
                                </div>

                                
                            </div>
                            <div class="tab-pane fade" id="pills-profile" role="tabpanel"
                                aria-labelledby="pills-profile-tab">

                                <div class="question-box">
                                    <Link to="/forum-details">
                                    <div class="question-inner">
                                        <div class="user-img">
                                            <img src="images/user.jpg" alt="user-img"
                                                    class="img-fluid" />
                                            <ul class="question-vote">
                                                <li class="question-vote-up"><i
                                                            class="fa-solid fa-caret-up"></i></li>
                                                <li class="vote_result">134</li>
                                                <li class="question-vote-down"><i
                                                            class="fa-solid fa-caret-down"></i></li>
                                            </ul>
                                        </div>

                                        <div class="question-content">
                                            <div class="question-header">
                                                <span class="user-name">James Wane</span>
                                                <span class="badge bg-danger">Enlightened</span>
                                                <div class="post">
                                                    <span class="post-date published">Asked: <span>April 19,
                                                            2023</span></span>
                                                    <span class="published">In:<span>Analytics</span></span>
                                                </div>

                                            </div>
                                            <h5>What are your thoughts on Google Analytics vs other analytics
                                                    platforms?</h5>
                                            <p class="question-wrp">Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                                Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                            </p>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="question-footer d-flex justify-content-between">
                                        <ul class="question-review">
                                            <li class="like"><i class="fa-solid fa-thumbs-up"></i></li>
                                            <li class="emoji">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span>172 Users</span>
                                            </li>
                                            <li class="comment-box">
                                                <i class="fa-solid fa-comment"></i>1
                                            </li>
                                            <li class="comment-box">
                                                <i class="fa-solid fa-comment"></i>2
                                            </li>
                                            <li>
                                                <i class="fa-regular fa-eye"></i>
                                                <span class="view">9k
                                                    Views</span>
                                            </li>
                                        </ul>
                                        <div class="vote">
                                            <span class="btn btn-secondary2">Answer</span>
                                        </div>
                                    </div>
                                    </Link>
                                </div>

                                <div class="question-box">
                                    <Link to="/forum-details">
                                    <div class="question-inner">
                                        <div class="user-img">
                                            <img src="images/user.jpg" alt="user-img"
                                                    class="img-fluid" />
                                            <ul class="question-vote">
                                                <li class="question-vote-up"><i
                                                            class="fa-solid fa-caret-up"></i></li>
                                                <li class="vote_result">134</li>
                                                <li class="question-vote-down"><i
                                                            class="fa-solid fa-caret-down"></i></li>
                                            </ul>
                                        </div>

                                        <div class="question-content">
                                            <div class="question-header">
                                                <span class="user-name">James Wane</span>
                                                <span class="badge bg-danger">Enlightened</span>
                                                <div class="post">
                                                    <span class="post-date published">Asked: <span>April 19,
                                                            2023</span></span>
                                                    <span class="published">In:<span>Analytics</span></span>
                                                </div>

                                            </div>
                                            <h5>What are your thoughts on Google Analytics vs other analytics
                                                    platforms?</h5>
                                            <p class="question-wrp">Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                                Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                            </p>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="question-footer d-flex justify-content-between">
                                        <ul class="question-review">
                                            <li class="like"><i class="fa-solid fa-thumbs-up"></i></li>
                                            <li class="emoji">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span>172 Users</span>
                                            </li>
                                            <li class="comment-box">
                                                <i class="fa-solid fa-comment"></i>1
                                            </li>
                                            <li class="comment-box">
                                                <i class="fa-solid fa-comment"></i>2
                                            </li>
                                            <li>
                                                <i class="fa-regular fa-eye"></i>
                                                <span class="view">9k
                                                    Views</span>
                                            </li>
                                        </ul>
                                        <div class="vote">
                                            <span class="btn btn-secondary2">Answer</span>
                                        </div>
                                    </div>
                                    </Link>
                                </div>

                                <div class="question-box">
                                    <Link to="/forum-details">
                                    <div class="question-inner">
                                        <div class="user-img">
                                            <img src="images/user.jpg" alt="user-img"
                                                    class="img-fluid" />
                                            <ul class="question-vote">
                                                <li class="question-vote-up"><i
                                                            class="fa-solid fa-caret-up"></i></li>
                                                <li class="vote_result">134</li>
                                                <li class="question-vote-down"><i
                                                            class="fa-solid fa-caret-down"></i></li>
                                            </ul>
                                        </div>

                                        <div class="question-content">
                                            <div class="question-header">
                                                <span class="user-name">James Wane</span>
                                                <span class="badge bg-danger">Enlightened</span>
                                                <div class="post">
                                                    <span class="post-date published">Asked: <span>April 19,
                                                            2023</span></span>
                                                    <span class="published">In:<span>Analytics</span></span>
                                                </div>

                                            </div>
                                            <h5>What are your thoughts on Google Analytics vs other analytics
                                                    platforms?</h5>
                                            <p class="question-wrp">Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                                Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                            </p>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="question-footer d-flex justify-content-between">
                                        <ul class="question-review">
                                            <li class="like"><i class="fa-solid fa-thumbs-up"></i></li>
                                            <li class="emoji">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span>172 Users</span>
                                            </li>
                                            <li class="comment-box">
                                                <i class="fa-solid fa-comment"></i>1
                                            </li>
                                            <li class="comment-box">
                                                <i class="fa-solid fa-comment"></i>2
                                            </li>
                                            <li>
                                                <i class="fa-regular fa-eye"></i>
                                                <span class="view">9k
                                                    Views</span>
                                            </li>
                                        </ul>
                                        <div class="vote">
                                            <span class="btn btn-secondary2">Answer</span>
                                        </div>
                                    </div>
                                    </Link>
                                </div>


                            </div>
                            <div class="tab-pane fade" id="pills-contact" role="tabpanel"
                                aria-labelledby="pills-contact-tab">

                                <div class="question-box">
                                    <Link to="/forum-details">
                                    <div class="question-inner">
                                        <div class="user-img">
                                            <img src="images/user.jpg" alt="user-img"
                                                    class="img-fluid" />
                                            <ul class="question-vote">
                                                <li class="question-vote-up"><i
                                                            class="fa-solid fa-caret-up"></i></li>
                                                <li class="vote_result">134</li>
                                                <li class="question-vote-down"><i
                                                            class="fa-solid fa-caret-down"></i></li>
                                            </ul>
                                        </div>

                                        <div class="question-content">
                                            <div class="question-header">
                                                <span class="user-name">James Wane</span>
                                                <span class="badge bg-danger">Enlightened</span>
                                                <div class="post">
                                                    <span class="post-date published">Asked: <span>April 19,
                                                            2023</span></span>
                                                    <span class="published">In:<span>Analytics</span></span>
                                                </div>

                                            </div>
                                            <h5>What are your thoughts on Google Analytics vs other analytics
                                                    platforms?</h5>
                                            <p class="question-wrp">Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                                Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                            </p>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="question-footer d-flex justify-content-between">
                                        <ul class="question-review">
                                            <li class="like"><i class="fa-solid fa-thumbs-up"></i></li>
                                            <li class="emoji">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span>172 Users</span>
                                            </li>
                                            <li class="comment-box">
                                                <i class="fa-solid fa-comment"></i>1
                                            </li>
                                            <li class="comment-box">
                                                <i class="fa-solid fa-comment"></i>2
                                            </li>
                                            <li>
                                                <i class="fa-regular fa-eye"></i>
                                                <span class="view">9k
                                                    Views</span>
                                            </li>
                                        </ul>
                                        <div class="vote">
                                            <span class="btn btn-secondary2">Answer</span>
                                        </div>
                                    </div>
                                    </Link>
                                </div>

                                <div class="question-box">
                                    <Link to="/forum-details">
                                    <div class="question-inner">
                                        <div class="user-img">
                                            <img src="images/user.jpg" alt="user-img"
                                                    class="img-fluid" />
                                            <ul class="question-vote">
                                                <li class="question-vote-up"><i
                                                            class="fa-solid fa-caret-up"></i></li>
                                                <li class="vote_result">134</li>
                                                <li class="question-vote-down"><i
                                                            class="fa-solid fa-caret-down"></i></li>
                                            </ul>
                                        </div>

                                        <div class="question-content">
                                            <div class="question-header">
                                                <span class="user-name">James Wane</span>
                                                <span class="badge bg-danger">Enlightened</span>
                                                <div class="post">
                                                    <span class="post-date published">Asked: <span>April 19,
                                                            2023</span></span>
                                                    <span class="published">In:<span>Analytics</span></span>
                                                </div>

                                            </div>
                                            <h5>What are your thoughts on Google Analytics vs other analytics
                                                    platforms?</h5>
                                            <p class="question-wrp">Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                                Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                            </p>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="question-footer d-flex justify-content-between">
                                        <ul class="question-review">
                                            <li class="like"><i class="fa-solid fa-thumbs-up"></i></li>
                                            <li class="emoji">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span>172 Users</span>
                                            </li>
                                            <li class="comment-box">
                                                <i class="fa-solid fa-comment"></i>1
                                            </li>
                                            <li class="comment-box">
                                                <i class="fa-solid fa-comment"></i>2
                                            </li>
                                            <li>
                                                <i class="fa-regular fa-eye"></i>
                                                <span class="view">9k
                                                    Views</span>
                                            </li>
                                        </ul>
                                        <div class="vote">
                                            <span class="btn btn-secondary2">Answer</span>
                                        </div>
                                    </div>
                                    </Link>
                                </div>

                                <div class="question-box">
                                    <Link to="/forum-details">
                                    <div class="question-inner">
                                        <div class="user-img">
                                            <img src="images/user.jpg" alt="user-img"
                                                    class="img-fluid" />
                                            <ul class="question-vote">
                                                <li class="question-vote-up"><i
                                                            class="fa-solid fa-caret-up"></i></li>
                                                <li class="vote_result">134</li>
                                                <li class="question-vote-down"><i
                                                            class="fa-solid fa-caret-down"></i></li>
                                            </ul>
                                        </div>

                                        <div class="question-content">
                                            <div class="question-header">
                                                <span class="user-name">James Wane</span>
                                                <span class="badge bg-danger">Enlightened</span>
                                                <div class="post">
                                                    <span class="post-date published">Asked: <span>April 19,
                                                            2023</span></span>
                                                    <span class="published">In:<span>Analytics</span></span>
                                                </div>

                                            </div>
                                            <h5>What are your thoughts on Google Analytics vs other analytics
                                                    platforms?</h5>
                                            <p class="question-wrp">Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                                Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                            </p>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="question-footer d-flex justify-content-between">
                                        <ul class="question-review">
                                            <li class="like"><i class="fa-solid fa-thumbs-up"></i></li>
                                            <li class="emoji">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span>172 Users</span>
                                            </li>
                                            <li class="comment-box">
                                                <i class="fa-solid fa-comment"></i>1
                                            </li>
                                            <li class="comment-box">
                                                <i class="fa-solid fa-comment"></i>2
                                            </li>
                                            <li>
                                                <i class="fa-regular fa-eye"></i>
                                                <span class="view">9k
                                                    Views</span>
                                            </li>
                                        </ul>
                                        <div class="vote">
                                            <span class="btn btn-secondary2">Answer</span>
                                        </div>
                                    </div>
                                    </Link>
                                </div>


                            </div>
                            <div class="tab-pane fade" id="pills-visited" role="tabpanel"
                                aria-labelledby="pills-contact-tab">

                                <div class="question-box">
                                    <Link to="/forum-details">
                                    <div class="question-inner">
                                        <div class="user-img">
                                            <img src="images/user.jpg" alt="user-img"
                                                    class="img-fluid" />
                                            <ul class="question-vote">
                                                <li class="question-vote-up"><i
                                                            class="fa-solid fa-caret-up"></i></li>
                                                <li class="vote_result">134</li>
                                                <li class="question-vote-down"><i
                                                            class="fa-solid fa-caret-down"></i></li>
                                            </ul>
                                        </div>

                                        <div class="question-content">
                                            <div class="question-header">
                                                <span class="user-name">James Wane</span>
                                                <span class="badge bg-danger">Enlightened</span>
                                                <div class="post">
                                                    <span class="post-date published">Asked: <span>April 19,
                                                            2023</span></span>
                                                    <span class="published">In:<span>Analytics</span></span>
                                                </div>

                                            </div>
                                            <h5>What are your thoughts on Google Analytics vs other analytics
                                                    platforms?</h5>
                                            <p class="question-wrp">Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                                Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                            </p>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="question-footer d-flex justify-content-between">
                                        <ul class="question-review">
                                            <li class="like"><i class="fa-solid fa-thumbs-up"></i></li>
                                            <li class="emoji">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span>172 Users</span>
                                            </li>
                                            <li class="comment-box">
                                                <i class="fa-solid fa-comment"></i>1
                                            </li>
                                            <li class="comment-box">
                                                <i class="fa-solid fa-comment"></i>2
                                            </li>
                                            <li>
                                                <i class="fa-regular fa-eye"></i>
                                                <span class="view">9k
                                                    Views</span>
                                            </li>
                                        </ul>
                                        <div class="vote">
                                            <span class="btn btn-secondary2">Answer</span>
                                        </div>
                                    </div>
                                    </Link>
                                </div>

                                <div class="question-box">
                                    <Link to="/forum-details">
                                    <div class="question-inner">
                                        <div class="user-img">
                                            <img src="images/user.jpg" alt="user-img"
                                                    class="img-fluid" />
                                            <ul class="question-vote">
                                                <li class="question-vote-up"><i
                                                            class="fa-solid fa-caret-up"></i></li>
                                                <li class="vote_result">134</li>
                                                <li class="question-vote-down"><i
                                                            class="fa-solid fa-caret-down"></i></li>
                                            </ul>
                                        </div>

                                        <div class="question-content">
                                            <div class="question-header">
                                                <span class="user-name">James Wane</span>
                                                <span class="badge bg-danger">Enlightened</span>
                                                <div class="post">
                                                    <span class="post-date published">Asked: <span>April 19,
                                                            2023</span></span>
                                                    <span class="published">In:<span>Analytics</span></span>
                                                </div>

                                            </div>
                                            <h5>What are your thoughts on Google Analytics vs other analytics
                                                    platforms?</h5>
                                            <p class="question-wrp">Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                                Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                            </p>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="question-footer d-flex justify-content-between">
                                        <ul class="question-review">
                                            <li class="like"><i class="fa-solid fa-thumbs-up"></i></li>
                                            <li class="emoji">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span>172 Users</span>
                                            </li>
                                            <li class="comment-box">
                                                <i class="fa-solid fa-comment"></i>1
                                            </li>
                                            <li class="comment-box">
                                                <i class="fa-solid fa-comment"></i>2
                                            </li>
                                            <li>
                                                <i class="fa-regular fa-eye"></i>
                                                <span class="view">9k
                                                    Views</span>
                                            </li>
                                        </ul>
                                        <div class="vote">
                                            <span class="btn btn-secondary2">Answer</span>
                                        </div>
                                    </div>
                                    </Link>
                                </div>

                                <div class="question-box">
                                    <Link to="/forum-details">
                                    <div class="question-inner">
                                        <div class="user-img">
                                            <img src="images/user.jpg" alt="user-img"
                                                    class="img-fluid" />
                                            <ul class="question-vote">
                                                <li class="question-vote-up"><i
                                                            class="fa-solid fa-caret-up"></i></li>
                                                <li class="vote_result">134</li>
                                                <li class="question-vote-down"><i
                                                            class="fa-solid fa-caret-down"></i></li>
                                            </ul>
                                        </div>

                                        <div class="question-content">
                                            <div class="question-header">
                                                <span class="user-name">James Wane</span>
                                                <span class="badge bg-danger">Enlightened</span>
                                                <div class="post">
                                                    <span class="post-date published">Asked: <span>April 19,
                                                            2023</span></span>
                                                    <span class="published">In:<span>Analytics</span></span>
                                                </div>

                                            </div>
                                            <h5>What are your thoughts on Google Analytics vs other analytics
                                                    platforms?</h5>
                                            <p class="question-wrp">Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                                Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                            </p>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div class="question-footer d-flex justify-content-between">
                                        <ul class="question-review">
                                            <li class="like"><i class="fa-solid fa-thumbs-up"></i></li>
                                            <li class="emoji">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span>172 Users</span>
                                            </li>
                                            <li class="comment-box">
                                                <i class="fa-solid fa-comment"></i>1
                                            </li>
                                            <li class="comment-box">
                                                <i class="fa-solid fa-comment"></i>2
                                            </li>
                                            <li>
                                                <i class="fa-regular fa-eye"></i>
                                                <span class="view">9k
                                                    Views</span>
                                            </li>
                                        </ul>
                                        <div class="vote">
                                            <span class="btn btn-secondary2">Answer</span>
                                        </div>
                                    </div>
                                    </Link>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="forums-sidebar">
                        <h4 class="text-center"><i class="fa-solid fa-chart-line"></i><span>Our Statistics</span></h4>
                        <hr/>
                        <ul class="sidebar-cart">
                            <li>
                                <div class="d-flex justify-content-between card-item">
                                    <div class="card-icon"><i class="fa-solid fa-message"></i></div>
                                    <div class="card-text w-100 d-flex justify-content-between"> <span class="stats-text">
                                            Questions </span>
                                        <span class="stats-value"> 19 </span>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="d-flex justify-content-between card-item">
                                    <div class="card-icon"><i class="fa-solid fa-comments"></i></div>
                                    <div class="card-text w-100 d-flex justify-content-between"> <span class="stats-text">
                                            Answers </span>
                                        <span class="stats-value"> 39 </span>
                                    </div>
                                </div>
                            </li>

                            <li>
                                <div class="d-flex justify-content-between card-item">
                                    <div class="card-icon"><i class="fa-solid fa-award"></i></div>
                                    <div class="card-text w-100 d-flex justify-content-between"> <span class="stats-text">
                                            Best Answers </span>
                                        <span class="stats-value"> 5 </span>
                                    </div>
                                </div>
                            </li>

                            <li>
                                <div class="d-flex justify-content-between card-item">
                                    <div class="card-icon"><i class="fa-solid fa-user-group"></i></div>
                                    <div class="card-text w-100 d-flex justify-content-between"> <span class="stats-text">
                                            Users </span>
                                        <span class="stats-value"> 58 </span>
                                    </div>
                                </div>
                            </li>


                        </ul>
                    </div>
                    <div class="popular-question forums-sidebar">
                        <h4 class="text-center"><i class="fa-solid fa-folder"></i><span>
                                Popular Questions</span></h4>
                        <hr/>
                        <ul class="question-item">
                            <li>
                                <Link to="#">
                                    <span class="author-img"><img src="images/user.jpg" alt="user-img"
                                            class="img-fluid"/></span>
                                    <div class="author-details">
                                        <span class="author-name">Sophie Taylor</span>
                                        <span class="content">Would it be possible to give a human artificial gills?</span>
                                        <div class="author-comment_box">
                                            <span><i class="fa-solid fa-comment"></i>2</span>
                                            <span><i class="fa-solid fa-comment"></i>4</span>
                                        </div>
                                    </div>
                                </Link>
                            </li>

                            <li>
                                <Link to="#">
                                    <span class="author-img"><img src="images/user.jpg" alt="user-img"
                                            class="img-fluid"/></span>
                                    <div class="author-details">
                                        <span class="author-name">Sophie Taylor</span>
                                        <span class="content">Would it be possible to give a human artificial gills?</span>
                                        <div class="author-comment_box">
                                            <span><i class="fa-solid fa-comment"></i>2</span>
                                            <span><i class="fa-solid fa-comment"></i>4</span>
                                        </div>
                                    </div>
                                </Link>
                            </li>

                            <li>
                                <Link to="#">
                                    <span class="author-img"><img src="images/user.jpg" alt="user-img"
                                            class="img-fluid"/></span>
                                    <div class="author-details">
                                        <span class="author-name">Sophie Taylor</span>
                                        <span class="content">Would it be possible to give a human artificial gills?</span>
                                        <div class="author-comment_box">
                                            <span><i class="fa-solid fa-comment"></i>2</span>
                                            <span><i class="fa-solid fa-comment"></i>4</span>
                                        </div>
                                    </div>
                                </Link>
                            </li>

                            <li>
                                <Link to="#">
                                    <span class="author-img"><img src="images/user.jpg" alt="user-img"
                                            class="img-fluid"/></span>
                                    <div class="author-details">
                                        <span class="author-name">Sophie Taylor</span>
                                        <span class="content">Would it be possible to give a human artificial gills?</span>
                                        <div class="author-comment_box">
                                            <span><i class="fa-solid fa-comment"></i>2</span>
                                            <span><i class="fa-solid fa-comment"></i>4</span>
                                        </div>
                                    </div>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div class="top-member forums-sidebar">
                        <h4 class="text-center"><i class="fa-solid fa-users"></i><span>
                                Top Members</span></h4>
                        <hr/>
                        <ul class="question-item top-member_list">
                            <li>
                                <Link to="#">
                                    <span class="author-img"><img src="images/user.jpg" alt="user-img"
                                            class="img-fluid"/></span>
                                    <div class="author-details">
                                        <span class="author-name">Sophie Taylor</span>
                                        <div class="author-comment_box">
                                            <span>3
                                                Questions</span>
                                            <span>616
                                                Points</span>
                                        </div>
                                    </div>
                                </Link>
                            </li>

                            <li>
                                <Link to="#">
                                    <span class="author-img"><img src="images/user.jpg" alt="user-img"
                                            class="img-fluid"/></span>
                                    <div class="author-details">
                                        <span class="author-name">Sophie Taylor</span>
                                        <div class="author-comment_box">
                                            <span>3
                                                Questions</span>
                                            <span>616
                                                Points</span>
                                        </div>
                                    </div>
                                </Link>
                            </li>

                            <li>
                                <Link to="#">
                                    <span class="author-img"><img src="images/user.jpg" alt="user-img"
                                            class="img-fluid"/></span>
                                    <div class="author-details">
                                        <span class="author-name">Sophie Taylor</span>
                                        <div class="author-comment_box">
                                            <span>3
                                                Questions</span>
                                            <span>616
                                                Points</span>
                                        </div>
                                    </div>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div class="forums-tag top-member forums-sidebar">
                        <h4 class="text-center"><i class="fa-solid fa-tags"></i><span>
                                Trending Tags</span></h4>
                        <hr/>
                        <p class="tag">
                            <Link to="#">Lorem</Link>
                            <Link to="#">Lorem</Link>
                            <Link to="#">Lorem</Link>
                            <Link to="#">Lorem</Link>
                            <Link to="#">Lorem</Link>
                            <Link to="#">Lorem</Link>
                            <Link to="#">Lorem</Link>
                            <Link to="#">Lorem</Link>
                            <Link to="#">Lorem</Link>
                            <Link to="#">Lorem</Link>
                            <Link to="#">Lorem</Link>
                            <Link to="#">Lorem</Link>
                            <Link to="#">Lorem</Link>
                            <Link to="#">Lorem</Link>
                            <Link to="#">Lorem</Link>
                            <Link to="#">Lorem</Link>
                            <Link to="#">Lorem</Link>
                            <Link to="#">Lorem</Link>
                            <Link to="#">Lorem</Link>
                            <Link to="#">Lorem</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  );
}

export default ForumList;
