import React, { useEffect, useState } from "react";
import ProfileSection from "./ProfileSection";
import SideBar from "../UserDashboard/SideBar";
import * as API from "../../API/Index.js";
import { IMG } from "../../API/constant.js";
import { Link } from "react-router-dom";
const AwardList = ({ data, setIsLogin }) => {
  const [movieList, setMovieList] = useState("");
  const userDataGetById = async () => {
    const header = localStorage.getItem("_tokenCode");
    try {
      const reQobj = {
        user_id: localStorage.getItem("_userId"),
      };
      const response = await API.allAward(
        localStorage.getItem("_userId"),
        header
      );
      setMovieList(response.data.data);
      console.log("response", response);
    } catch (error) {}
  };

  useEffect(() => {
    userDataGetById();
  }, []);
  return (
    <>
      <ProfileSection formData={data} />
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="jj">
            <div class="tab_main editProfile">
              <div class="row justify-content-center g-0">
                <div class="col-sm-12 col-md-12 row">
                  <div className="col-md-3">
                    <SideBar formData={data} setIsLogin={setIsLogin} />
                  </div>
                  <div className="col-md-9">
                    <div className="tab-content">
                      <div className="row">
                        <div className="row mb-3">
                          <div className="col-md-9">
                            <h4 class="mb-3">Awards</h4>
                          </div>
                          <div className="col-md-3 text-end">
                            <Link
                              className="btn btn-secondary2"
                              to="/add-award"
                            >
                              Add Awards
                            </Link>
                          </div>
                        </div>
                        <div className="table-responsive movieListTable">
                          <table class="table table-dark ">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Image</th>

                                {/* <th scope="col">Action </th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {movieList === undefined ||
                              movieList.length === 0 ? (
                                <h5 className="d-block mt-4 text-center">
                                  Data Not Found
                                </h5>
                              ) : (
                                movieList.map((item, index) => (
                                  <tr key={index}>
                                    <td>{item.title}</td>
                                    <td>
                                      <img
                                        className="w-25 h-50"
                                        src={IMG + item.img}
                                      />
                                    </td>
                                    {/* <td>{item.description}</td> */}

                                    {/* <td>
                                        <i class="fa-solid fa-trash"></i>
                                      </td> */}
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AwardList;
