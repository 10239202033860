import React from 'react';
import { Accordion } from 'react-bootstrap-accordion';

const HelpCenter = () => {
  return (
    <>
        <section class="movie_list">
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-sm-11">
                        <div class="row justify-content-center">
                            <div class="col-md-6 col-sm-10 helpCenter">
                                <div class="help mt-5 d-flex justify-content-center align-items-center flex-column">
                                    <h2>How can we help?</h2>
                                    <div class="relative help mt-4 mb-3">
                                        <input type="text" class="form-control" id="" placeholder="Email Address"/>
                                        <span class="searchIcon"><i class="fa-solid fa-magnifying-glass"></i></span>
                                    </div>
                                    <p><strong>Recommended for you : </strong> <a href="#">How to keep your account secure ,</a> <a href="#">Parental controls on Imboxo ,</a> <a href="#">How to change your plan</a></p>
                                </div>

                                <div class="explore" id="explore">
                                    <center>
                                        <h4 class="mb-4">Explore Topics</h4>
                                        <span><a href="#explore"><i class="fa-solid fa-arrow-down"></i></a></span>
                                    </center>

                                    <div class="topics mt-5">
                                        <h5><i class="fa-solid fa-user"></i>&nbsp;&nbsp; Account and Billing</h5>

                                        <div class="accordion mt-4 mb-4" id="accordionExample">
                                            <div class="accordion-item">
                                                <h2 class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                    Account Settings
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <ul>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i> How to change your plan</a></li>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i> How to cancel Imboxo</a></li>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i> How to change or reset your password</a></li>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i> How to update Imboxo account information</a></li>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i> Sharing your Imboxo account</a></li>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i> How to keep your account secure</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="accordion-item">
                                                <h2 class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    Paying for Imboxo
                                                    </button>
                                                </h2>
                                                <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <ul>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i>Billing and Payments</a></li>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i> How to remove payment methods from your account</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    <h5><i class="fa-solid fa-user"></i>&nbsp;&nbsp; Fix a Problem</h5>

                                        <div class="accordion mt-4 mb-4" id="accordionExample2">
                                            <div class="accordion-item">
                                                <h2 class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    Account Issues
                                                    </button>
                                                </h2>
                                                <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample2">
                                                    <div class="accordion-body">
                                                        <ul>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i> Can't sign in to Imboxo</a></li>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i> Imboxo says to sign up when trying to sign in</a></li>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i> Imboxo says account is already in use</a></li>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i> How to update Imboxo account information</a></li>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i> Sharing your Imboxo account</a></li>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i> How to keep your account secure</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="accordion-item">
                                                <h2 class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                    Billing Issues
                                                    </button>
                                                </h2>
                                                <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample2">
                                                    <div class="accordion-body">
                                                        <ul>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i>Imboxo says 'Your account is on hold because of a problem with your last payment.'</a></li>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i> Unrecognized or unauthorized charges from Imboxo</a></li>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i>Charged twice by Imboxo</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="accordion-item">
                                                <h2 class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                    Error Codes
                                                    </button>
                                                </h2>
                                                <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample2">
                                                    <div class="accordion-body">
                                                        <ul>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i>Imboxo isn't working</a></li>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i> Imboxo Error NW-2-5</a></li>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i>Imboxo Error ui-800-3</a></li>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i> Imboxo Error tvq-pb-101</a></li>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i>Imboxo Error 113</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="accordion-item">
                                                <h2 class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                    Problems Watching
                                                    </button>
                                                </h2>
                                                <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionExample2">
                                                    <div class="accordion-body">
                                                        <ul>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i>Fix a problem on your Android phone or tablet</a></li>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i>Fix a problem on your TV or streaming media player</a></li>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i>Imboxo says, 'This app is not compatible with your device.'</a></li>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i>Browser isn't supported</a></li>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i>Black screen with sound</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 

                                        <h5><i class="fa-solid fa-user"></i>&nbsp;&nbsp; Watching and Playing</h5>

                                        <div class="accordion mt-4 mb-4" id="accordionExample3">
                                            <div class="accordion-item">
                                                <h2 class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                    Profiles
                                                    </button>
                                                </h2>
                                                <div id="collapseSeven" class="accordion-collapse collapse" data-bs-parent="#accordionExample3">
                                                    <div class="accordion-body">
                                                        <ul>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i> How to create, change, or delete profiles</a></li>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i> How to change the language on Imboxo</a></li>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i> Profile transfers</a></li>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i> How to update Imboxo account information</a></li>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i> Sharing your Imboxo account</a></li>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i> How to keep your account secure</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="accordion-item">
                                                <h2 class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                                    Features and Settings
                                                    </button>
                                                </h2>
                                                <div id="collapseEight" class="accordion-collapse collapse" data-bs-parent="#accordionExample3">
                                                    <div class="accordion-body">
                                                        <ul>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i>Using Imboxo outside of your home</a></li>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i> How to get the best video quality</a></li>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i>How to use subtitles, captions, or choose audio language</a></li>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i>How to use 'My List'</a></li>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i> Accessibility on Imboxo</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="accordion-item">
                                                <h2 class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                    Error Codes
                                                    </button>
                                                </h2>
                                                <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample3">
                                                    <div class="accordion-body">
                                                        <ul>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i>Imboxo isn't working</a></li>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i> Imboxo Error NW-2-5</a></li>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i>Imboxo Error ui-800-3</a></li>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i> Imboxo Error tvq-pb-101</a></li>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i>Imboxo Error 113</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="accordion-item">
                                                <h2 class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                    Problems Watching
                                                    </button>
                                                </h2>
                                                <div id="collapseSix" class="accordion-collapse collapse" data-bs-parent="#accordionExample3">
                                                    <div class="accordion-body">
                                                        <ul>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i>Fix a problem on your Android phone or tablet</a></li>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i>Fix a problem on your TV or streaming media player</a></li>
                                                            <li><a href="#"><i class="fa-regular fa-file-lines"></i>Imboxo says, 'This app is not compatible with your device.'</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                        
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
}

export default HelpCenter;
