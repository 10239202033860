import React, { useEffect, useState } from 'react';
import * as API from "../../API/Index.js";

const Contact = () => {
  const [contactInfo , setContactInfo] = useState({})

  const allContactInfo = async()=> {
    try {
        const response = await API.getContact()
        console.log("response",response);
        setContactInfo(response.data.data)
    } catch (error) {
        
    }
  }


  useEffect(() => {
    allContactInfo();
  },[])
    
  return (
    <>
        <section class="movie_list contact">
            <div class="container-fluid p-0">
                <div class="row justify-content-end">
                    <div class="col-11 col-md-5">
                        <h3 class="mb-3">Get In Touch</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptas neque tenetur, sit vero facilis impedit modi excepturi deserunt itaque est aliquam fugit voluptatem similique quasi qui fugiat suscipit ipsam? Quo?</p>
                        <div class="row mt-5 justify-content-center">
                            <div class="col-sm-4 col-md-4 text-center">
                            <span class="contactIcons"><i class="bi bi-geo-fill"></i></span>
                            <p>{contactInfo.address}</p>
                            </div>
                            <div class="col-sm-4 col-md-4 text-center">
                            <span class="contactIcons"><i class="bi bi-telephone-fill"></i></span>
                            <p><a href="tel:{contactInfo.mobile}">{contactInfo.mobile}</a></p>
                            </div>
                            <div class="col-sm-4 col-md-4 text-center">
                            <span class="contactIcons"><i class="bi bi-envelope-at"></i></span>
                            <p><a href="mailto:info@gmail.com">{contactInfo.email}</a></p>
                            </div>
                            
                            <div class="row">
                                <div class="col-12">
                                <h4 class="mt-4 mb-3">Send Message</h4>
                                </div>
                                <div class="col-sm-6">
                                    <input type="text" class="form-control mb-4" name="" id="" placeholder="Name"/>
                                </div>
                                <div class="col-sm-6">
                                    <input type="email" class="form-control mb-4" name="" id="" placeholder="Email"/>
                                </div>
                                <div class="col-sm-12">
                                    <textarea name="" id="" class="form-control mb-4" placeholder="Message"></textarea>
                                </div>
                                <div class="col-sm-12">
                                    <button class="btn btn-secondary2">Send Message</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-11 col-md-6">
                    <iframe src={contactInfo.map} width="100%" height="100%"  style={{border: 0 + 'px'}} llowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
}

export default Contact;
