import React from "react";
import { appOne } from "../AppUtilities";
import { appTwo } from "../AppUtilities";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer>
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-sm-2">
              <span class="logo">
                <Link to="/">
                  <img src="images/logo.jpg" alt="" class="img-fluid" />
                </Link>
              </span>
              <h6 class=" mt-4">Newsletter</h6>
              <div class="relative">
                <input
                  type="text"
                  class="form-control mt-2 mb-2"
                  id=""
                  placeholder="Email Address"
                />
                <button class="btn btn-sm btn-secondary2">
                  <i class="fa-solid fa-paper-plane"></i>
                </button>
              </div>

              <div class="d-flex justify-content-start">
                <span class="footer_social">
                  <Link to="#">
                    <i class="fa-brands fa-facebook-f"></i>
                  </Link>
                </span>
                <span class="footer_social">
                  <Link to="#">
                    <i class="fa-brands fa-twitter"></i>
                  </Link>
                </span>
                <span class="footer_social">
                  <Link to="#">
                    <i class="fa-brands fa-instagram"></i>
                  </Link>
                </span>
              </div>
            </div>
            <div class="col-sm-8">
              <div class="row">
                <div class="col">
                  <h6 class="footer_head">Support</h6>
                  <ul>
                    <li class="footer_link">
                      <Link to="/faqs">FAQ</Link>
                    </li>
                    <li class="footer_link">
                      <Link to="/help-center">Help Center</Link>
                    </li>
                    <li class="footer_link">
                      <Link to="/contact">Contact Support</Link>
                    </li>
                    <li class="footer_link">
                      <Link to="/feedback">Accessibility</Link>
                    </li>
                    <li class="footer_link">
                      <Link to="/feedback">Feedback</Link>
                    </li>
                  </ul>
                </div>
                <div class="col">
                  <h6 class="footer_head">LEGAL</h6>
                  <ul>
                    <li class="footer_link">
                      <Link to="#">Privacy Policy</Link>
                    </li>
                    <li class="footer_link">
                      <Link to="#">Terms of Service</Link>
                    </li>
                    <li class="footer_link">
                      <Link to="#">Legal Notices</Link>
                    </li>
                    <li class="footer_link">
                      <Link to="#">Do Not Sell My Personal Information</Link>
                    </li>
                  </ul>
                </div>
                <div class="col">
                  <h6 class="footer_head">ENGAGE WITH US
                  </h6>
                  <ul>
                    <li class="footer_link">
                      <Link to="#">Feedback</Link>
                    </li>
                    <li class="footer_link">
                      <Link to="#">Advertise with Us</Link>
                    </li>
                    <li class="footer_link">
                      <Link to="#">Become a Sponsor</Link>
                    </li>
                  </ul>
                </div>
                <div class="col">
                  <h6 class="footer_head">GET INVOLVED</h6>
                  <ul>
                    <li class="footer_link">
                      <Link to="#">Become a Judge</Link>
                    </li>
                    <li class="footer_link">
                      <Link to="#">Volunteer</Link>
                    </li>
                    <li class="footer_link">
                      <Link to="#">Careers</Link>
                    </li>
                    <li class="footer_link">
                      <Link to="#">Submit Your Film</Link>
                    </li>
                  </ul>
                </div>
                <div class="col">
                  <h6 class="footer_head">About IMBOXO</h6>
                  <ul>
                    <li class="footer_link">
                      <Link to="/about">About Us
                      </Link>
                    </li>
                    <li class="footer_link">
                      <Link to="/user-review">Our Mission & Vision</Link>
                    </li>
                    <li class="footer_link">
                      <Link to="/blogs">Press Releases</Link>
                    </li>
                  </ul>
                </div>
               
              </div>
            </div>

            <div class="col-sm-11 mt-3">
              <hr />
              <div class="row align-items-center">
                <div class="col-md-6">
                  <p class="footer_text">
                    Copyright 2024 © IMBOXO. Powered by
                    <Link to="/">WebArt Technology</Link> All Rights Reserved.
                  </p>
                </div>
                <div class="col-md-6 d-flex justify-content-end">
                  <small>
                    <Link to="/privacy-policy" class="footer_text">
                      Privacy Policy
                    </Link>
                  </small>
                  <small>
                    <Link to="/terms-and-conditions" class="footer_text">
                      Terms of Service
                    </Link>
                  </small>
                  <small>
                    <Link to="/feedback" class="footer_text">
                      feedback
                    </Link>
                  </small>
                  <small>
                    <Link to="#" class="footer_text">
                      Accessibility
                    </Link>
                  </small>
                  <small>
                    <Link to="#" class="footer_text">
                      Advertise with Us
                    </Link>
                  </small>
                  <small>
                    <Link to="#" class="footer_text">
                      Sitemap
                    </Link>
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
