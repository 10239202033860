import React, { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router";
import * as API from "../../API/Index.js";
import { MESSAGE } from "../../schemas/Validation.js";

import { BeatLoader } from "react-spinners";
import { type } from "@testing-library/user-event/dist/type/index.js";

import SideBar from "../UserDashboard/SideBar.js";
import ProfileSection from "../Creator/ProfileSection.js";
const initialValues = {
  name: "",
  email: "",
  city: "",
  state: "",
  country: "",
  address: "",
  phone: "",
  username: "",
  profile_img: "",
  banner_img: "",
  bio: "",
};
const EditProfile = ({ setIsLogin, data, roouserDataGetById }) => {
  const navigate = useNavigate();
  const [isLoder, setIsLoder] = useState(false);
  const [getUserData, setGetUserData] = useState("");
  const [allCountryData, setAllCountryData] = useState([]);
  const [allStateData, setAllStateData] = useState([]);
  const [allCityData, setAllCityData] = useState([]);
  const [firstSerise, setFirstSerise] = useState(false);
  const [formData, setFormData] = useState(initialValues);
  const [allCartifi, setAllCartifi] = useState("");
  const [countryData, setCountryData] = useState("");
  const [stateData, setStateData] = useState("");
  const [cityData, setCityData] = useState("");
  const [profieData, setProfieData] = useState("");
  const [coverData, setCoverData] = useState("");
  const [isMovie, setIsMovie] = useState(0);

  const handalerChangespro = async (e) => {
    setProfieData(e.target.files[0]);
    setIsLoder(false);
  };
  const handalerChangescov = async (e) => {
    setCoverData(e.target.files[0]);
    setIsLoder(false);
  };

  const handalerChanges = async (e) => {
    setIsLoder(false);
    const { name, value } = e.target;
    const header = localStorage.getItem("_tokenCode");
    if (name === "country") {
      console.log("setCountryData", e.target.value);
      setCountryData(parseInt(e.target.value));
      const stateresponse = await API.allState(e.target.value, header);
      setAllStateData(stateresponse.data.data);
    }
    if (name === "state") {
      setStateData(parseInt(e.target.value));
      const cityresponse = await API.allCity(e.target.value, header);
      console.log("cityresponse", cityresponse);
      setAllCityData(cityresponse.data.data);
    }

    if (name === "city") {
      setCityData(parseInt(e.target.value));
    }

    setFormData({ ...formData, [name]: value });
  };

  const userDataGetById = async () => {
    const header = localStorage.getItem("_tokenCode");
    try {
      const response = await API.getuserDataID(
        header,
        localStorage.getItem("_userId")
      );
      console.log("Editresponse", response);
      setFormData(response.data.data);
      setGetUserData(response.data.data);
      const cresponse = await API.allCountry(header);
      setAllCountryData(cresponse.data.data);

      // ? secoundtime run code country data
      if (formData.state === null) {
        console.log("heloo");
      } else {
        console.log("API CALLING");
        const stateresponse = await API.allState(
          response.data.data.country,
          header
        );
        setAllStateData(stateresponse.data.data);
      }

      if (formData.city === null) {
      } else {
        const cityresponse = await API.allCity(
          response.data.data.state,
          header
        );
        setAllCityData(cityresponse.data.data);
      }
      // if (response.data.is_login === false) {
      //   localStorage.removeItem("_tokenCode");
      //   localStorage.removeItem("isLogin");
      //   if (localStorage.getItem("isLogin") === null) {
      //     navigate("/login");
      //   }
      // }
    } catch (error) {}
  };

  const userdataUpdate = async () => {
    const header = localStorage.getItem("_tokenCode");
    setIsLoder(true);
    try {
      const reqObj = {
        name: formData.name,
        username: formData.username,
        profile_img: profieData,
        banner_img: coverData,
        city: formData.city,
        email: formData.email,
        state: formData.state,
        country: formData.country,
        address: formData.address,
        phone: formData.phone,
        user_id: localStorage.getItem("_userId"),
        bio: formData.bio,
      };
      console.log("reqObj", reqObj);
      const response = await API.getuser_update(reqObj, header);
      console.log("response", response);
      if (response.data.success === 1) {
        roouserDataGetById();
        userDataGetById();
        MESSAGE(response.data.message, 1);
        setIsLoder(false);
      } else {
        setIsLoder(false);
      }
      // const formDataReq = new FormData();
      // formDataReq.append("name", formData.name);
      // formDataReq.append("user_name", formData.user_name);
      // formDataReq.append("profile_img", profieData);
      // formDataReq.append("banner_img", coverData);
      // formDataReq.append(
      //   "city",
      //   typeof cityData === "number" ? cityData : formData.city
      // );
      // formDataReq.append("email", formData.email);
      // formDataReq.append("role", formData.user_type);
      // formDataReq.append(
      //   "state",
      //   typeof stateData === "number" ? stateData : formData.state
      // );
      // formDataReq.append(
      //   "country",
      //   typeof countryData === "number" ? countryData : formData.country
      // );
      // formDataReq.append("address", formData.address);
      // formDataReq.append("phone", formData.age_limit);

      // const response = await API.getuser_update(formDataReq, header);
      // console.log("response", response);
      // if (response.data.success === 1) {
      //   setIsLoder(false);
      //   userDataGetById();
      //   MESSAGE(response.data.msg, 1);
      // }
    } catch {}
  };

  const pageshowhideSerise = () => {
    setFirstSerise(!firstSerise);
  };

  useEffect(() => {
    userDataGetById();
  }, []);
  return (
    <>
      <ProfileSection formData={data} />
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="jj">
            <div class="tab_main editProfile">
              <div class="row justify-content-center g-0">
                <div class="col-sm-12 col-md-12 row">
                  <div className="col-md-3">
                    <SideBar formData={data} setIsLogin={setIsLogin} />
                  </div>
                  <div className="col-md-9">
                    <div className="tab-content">
                      <h4 class="mb-3">Edit Your profile</h4>
                      <div class="row">
                        <div class="mb-3 col-sm-6">
                          <label>Name </label>
                          <input
                            type="text"
                            placeholder="Enter Your Name"
                            class="form-control"
                            name="name"
                            value={formData.name}
                            onChange={handalerChanges}
                          />
                        </div>
                        <div
                          className={
                            formData.user_type === "user"
                              ? "d-none"
                              : "mb-3 col-sm-6"
                          }
                        >
                          <label>User Name </label>
                          <input
                            type="text"
                            placeholder="Enter Your user name"
                            class="form-control"
                            name="username"
                            readOnly
                            value={formData.username}
                            onChange={handalerChanges}
                          />
                        </div>
                        <div className="mb-3 col-sm-6">
                          <label>Profile image </label>
                          <input
                            type="file"
                            multiple
                            placeholder="Enter Your user name"
                            class="form-control"
                            name="profile_img"
                            onChange={handalerChangespro}
                          />
                        </div>
                        <div className="mb-3 col-sm-6">
                          <label>Cover image</label>
                          <input
                            type="file"
                            multiple
                            class="form-control"
                            name="banner_img"
                            onChange={handalerChangescov}
                          />
                        </div>
                        <div class="mb-3 col-sm-6">
                          <label>Email </label>
                          <input
                            type="text"
                            placeholder="Enter Your Email"
                            class="form-control"
                            name="email"
                            readOnly
                            value={formData.email}
                            onChange={handalerChanges}
                            autocomplete="off"
                          />
                        </div>
                        <div class="mb-3 col-sm-6">
                          <label>Phone No </label>
                          <input
                            type="text"
                            class="form-control"
                            id=""
                            placeholder="Phone No."
                            value={formData.phone}
                            onChange={handalerChanges}
                            name="phone"
                          />
                        </div>
                        <div class="mb-3 col-sm-6">
                          <label>Adderss</label>
                          <input
                            type="text"
                            class="form-control"
                            value={formData.address}
                            onChange={handalerChanges}
                            name="address"
                            placeholder="Address"
                          />
                        </div>
                        <div class="mb-3 col-sm-4 col-md-6">
                          <label>Country </label>
                          <select
                            value={formData.country}
                            onChange={handalerChanges}
                            name="country"
                            class="form-control form-select"
                          >
                            <option>--- Select ---</option>
                            {allCountryData.map((item, index) => (
                              <option key={index} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div class="mb-3 col-sm-4 col-md-6">
                          <label>State </label>

                          <select
                            onChange={handalerChanges}
                            value={formData.state}
                            name="state"
                            class="form-control form-select"
                          >
                            <option>--- Select ---</option>
                            {allStateData === undefined
                              ? ""
                              : allStateData.map((item, index) => (
                                  <option value={item.id} key={index}>
                                    {item.name}
                                  </option>
                                ))}
                          </select>
                        </div>
                        <div class="mb-3 col-sm-4 col-md-6">
                          <label>City </label>
                          <select
                            value={formData.city}
                            onChange={handalerChanges}
                            name="city"
                            class="form-control form-select"
                          >
                            <option>--- Select ---</option>
                            {allCityData === undefined
                              ? ""
                              : allCityData.map((item, index) => (
                                  <option value={item.id}>{item.name}</option>
                                ))}
                          </select>
                        </div>

                        <div class="mb-3 col-sm-4 col-md-12">
                          <label>Bio </label>
                          <textarea
                            class="form-control "
                            rows="5"
                            cols="10"
                            style={{ height: 100 }}
                            placeholder="Bio"
                            value={formData.bio}
                            name="bio"
                            onChange={handalerChanges}
                          ></textarea>
                          {/* <select
                            value={formData.city}
                            onChange={handalerChanges}
                            name="bio"
                            class="form-control form-select"
                          >
                            <option>--- Select ---</option>
                            {allCityData === undefined
                              ? ""
                              : allCityData.map((item, index) => (
                                  <option value={item.id}>{item.name}</option>
                                ))}
                          </select> */}
                        </div>

                        <div class="mt-3 text-center col-12">
                          {isLoder ? (
                            <button
                              type="button"
                              class="btn btn btn-secondary2 mt-3"
                            >
                              <BeatLoader color="#fff" />
                            </button>
                          ) : (
                            <button
                              type="button"
                              class="btn btn btn-secondary2 mt-3"
                              onClick={userdataUpdate}
                            >
                              Update
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
