import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { topMovieSlider } from "../AppUtilities";
import { IMG } from "../API/constant";
import { Link } from "react-router-dom";

const MovieSlider = ({ topWeekMovie }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 600, min: 414 },
      items: 2,
    },
    smallmobile: {
      breakpoint: { max: 414, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <section class="top_week movieWeek pt-4 pb-5">
        <div class="container-fluid">
          <div class="justify-content-center row">
            <div class="col-11 d-flex align-items-center justify-content-between">
              <h3>Top Movies of the week</h3>
              {/* <span>
                <a href="#" class="text-light">
                  View all
                </a>
              </span> */}
            </div>
            <div class="col-11">
              <hr class="mt-4 mb-4" />

              <Carousel
                responsive={responsive}
                swipeable={true}
                draggable={true}
                showDots={true}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={3000}
                customTransition="all 1s linear"
              >
                {topWeekMovie.map((item, index) => (
                  <div>
                    <div class="item floatContent">
                      <Link to={`/movie-details/${item.slug}`}>
                        <img
                          src={IMG + item.poster}
                          alt=""
                          class="w-100 main_img"
                        />
                        <div class="week_content">
                          <center>
                            <h3>
                              {index === 10 ? index + 1 : `0${index + 1}`}
                            </h3>
                          </center>
                        </div>
                      </Link>
                    </div>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MovieSlider;
