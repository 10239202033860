import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import * as API from "../../API/Index.js";
import { MESSAGE } from '../../schemas/Validation.js';
import { useNavigate } from 'react-router';


const initialData = {
    feedback:'',

  }  

const FeedbackStore = () => {
  
    const navigate = useNavigate()

   const userRef = useRef()

   const [formData, setFormData] = useState(initialData);
   const [allFeedback , setAllFeedback] = useState([])


   const handleChange=(e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
   }

   const submitData = async() => {
    try {
        const reqObj = {
            feedback:formData.feedback,
            user_id:localStorage.getItem('_userId'),
        }
        console.log("reqObj",reqObj);
        const response = await API.feedbackStore(reqObj)
        if (response.data.status === "error") {
            MESSAGE("Please Login")
            navigate("/login")
        }
        if (response.data.success === 1) {
            MESSAGE(response.data.message,1)
         
        }
        console.log('response' , response);
        
    } catch (error) {
        
    }
    }

    const allFeedList = async()=>{
        try {
          const response = await API.getTeam();
          console.log('response', response);
          setAllFeedback(response.data.data)
          
        } catch (error) {
          
        }
      }

    useEffect(() => {
        submitData();
        allFeedList();
    },[])

    
  return (
    <div>
        <section class="movie_list feedStore">
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-sm-6 col-md-5">
                        <h2 class="text-center mb-4">Put Your Feedback Here</h2>
                        
                        <div class="mt-3 mb-3">
                            <textarea name="feedback" id="" class="form-control mb-4" placeholder="Message"
                            onChange={handleChange}
                            value={formData.feedback}
                            ></textarea>
                        </div>
                        <div class="mt-3 mb-3 text-center" >
                        <button class="btn btn-secondary2" onClick={submitData}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>    
        

        </section>  
        <section class=" feedback">
        <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-md-11 text-center">
                <h2>Feedback</h2>

                <div class="row">
                {allFeedback.map((item, index)=>(
                    <div class="col-sm-6 col-md-4 col-lg-3 mt-4">
                        <div class="feedBackBox text-center">
                            <span class="quote"><i class="bi bi-quote"></i></span>
                            {/* <img src="images/user.jpg" alt="user" class="img-fluid"/> */}
                            <p>{allFeedback.feedback}</p>
                            <hr/>
                            <strong>User Name</strong>
                            <small>Country -USA</small>

                        </div>
                    </div>
                ))}
                    
                </div>
            </div>
            
            
        </div>
        </div>
        </section>
    </div>
  );
}

export default FeedbackStore;
