import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import * as API from "../../API/Index.js";
import "react-multi-carousel/lib/styles.css";
import { IMG } from '../../API/constant.js';

const TeamSlide = () => {

  const [allTeamMember , setAllTeamMember] = useState([])

  const allTeam = async()=>{
    try {
      const response = await API.getTeam();
      console.log('response', response);
      setAllTeamMember(response.data.data)
      
    } catch (error) {
      
    }
  }

  useEffect(() => {
    allTeam()
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <div className="custom-carousel-container mb-5">
        <Carousel
          responsive={responsive}
          swipeable={true}
          draggable={true}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          showDots={true}  // Hide dots
          arrows={false}   // Hide arrows
          autoPlay={true}
          autoPlaySpeed={3000}
        >
          

            {allTeamMember.map((item, index) =>(
              <div>
                <div class="item">
                  <a href="#">
                      <span><img src={IMG+ item.image} alt="" class="img-fluid"/></span>
                      <h5>{item.name}</h5>
                      <p>{item.designation}</p>
                  </a>
                </div>
              </div>
            ))}
              
         
         

          
        </Carousel>
      </div>  
    </>
  );
};

export default TeamSlide;
