import { homeBanners } from "../AppUtilities";
import { homeSliders } from "../AppUtilities";
import { brand } from "../AppUtilities";
import HomeBannerSlide from "./HomeBannerSlide";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { tailer } from "../AppUtilities";
import { bannerMovies } from "../AppUtilities";
import { Link } from "react-router-dom";
import { IMG } from "../API/constant";
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Autoplay, EffectFade, Pagination, Navigation } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useEffect, useRef, useState } from "react";
import MovieDetailsModal from "../pages/Movie/MovieDetailsModal";
import * as API from "../API/Index";
import ReactPlayer from "react-player";
import VideoPlayer from "./Video/VideoPlayer";
const HomeBanner = ({ trandingMovie, bannerData }) => {
  const [videoFormat, setVideoFormat] = useState("");
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const [individualMoviles, setIndividualMoviles] = useState("");

  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState("");
  const videoRef = useRef(null);

  console.log("isPlaying", isPlaying);

  //console.log("isMuted", isMuted);

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  // const handleVideoClick = () => {
  //   if (isPlaying) {
  //     videoRef.current.pause();
  //   } else {
  //     videoRef.current.play();
  //   }
  //   setIsPlaying(!isPlaying);
  // };

  const individualMovie = async (data) => {
    console.log("data", data);

    setOpen(true);
    const header = localStorage.getItem("_tokenCode");
    try {
      const response = await API.getMovieDetils(data);
      console.log("response", response);
      setIndividualMoviles(response.data.data);
      const reqObj = {
        type: "movie",
        id: response.data.data.id,
      };
      // console.log("reqObj", reqObj);
      const sresponse = await API.getMovieDetailsS3(reqObj, header);
      setVideoFormat(sresponse.data.fileUrl);
      // console.log("response", response);
    } catch (error) {}
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      //setIsMuted(false);
      //handleVideoClick();
    }, 5000);
    // ?**Cleanup function to clear the timeout if the component unmounts or dependencies change
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <section class="home_banner">
        {/* <img
          src={
            trandingMovie.length === 0 ? "" : IMG + trandingMovie[0].thumbnail
          }
          alt=""
          class="w-
          100"
        /> */}

        {/* <Swiper
          spaceBetween={30}
          effect={"fade"}
          speed={5000}
          fadeEffect={{ crossFade: true }} // Smooth crossfade between slides
          loop={true} // Enable looping
          // navigation={true}
          // pagination={{
          //   clickable: true,
          // }}
          autoplay={{
            delay: 500,
            disableOnInteraction: false,
          }}
          modules={[EffectFade, Autoplay, Navigation, Pagination]}
          className="mySwiper"
        >
          {trandingMovie.map((item, index) => (
            <SwiperSlide key={index}>
              <img src={IMG + item.poster} alt="" class="w-100" />
              <div class="btngroup mt-4">
                <span>
                  <Link
                    to={`/movie-details/${item.slug}`}
                    class="btn banner_btn btn-light bnner_play"
                  >
                    <i class="fa-solid fa-play"></i> Play
                  </Link>
                </span>
                <span>
                  <Link to="#" class="btn banner_btn btn-light">
                    <i class="fa-solid fa-circle-info"></i> more info
                  </Link>
                </span>
              </div>
            </SwiperSlide>
          ))}
        </Swiper> */}

        <div className="tailreMove">
          <VideoPlayer videoType={2} videoFormat={bannerData.trailer} />
        </div>

        <div class="banner_infos_listing">
          <div class="movieInfo">
            <span>
              {/* <img src={brand} alt="logo" class="img-fluid" /> */}
            </span>
          </div>
          <div class="btngroup mt-4">
            <span>
              <Link
                to={`/movie-details/${
                  bannerData.length === 0 ? "" : bannerData.movie.slug
                }`}
                class="btn banner_btn btn-light bnner_play"
              >
                <i class="fa-solid fa-play"></i> Play
              </Link>
            </span>
            <span>
              <Link
                to="#"
                class="btn banner_btn btn-light"
                onClick={() => individualMovie(bannerData.movie.slug)}
              >
                <i class="fa-solid fa-circle-info"></i> more info
              </Link>
            </span>
            <span className="cartifict">
              {bannerData.length === 0 ? "" : bannerData.movie.certificate}
            </span>
          </div>
        </div>
      </section>

      {/* modal */}
      {individualMoviles ? (
        <MovieDetailsModal
          open={open}
          onCloseModal={onCloseModal}
          videoFormat={videoFormat}
          individualMoviles={individualMoviles}
        />
      ) : (
        ""
      )}
    </>
  );
};
export default HomeBanner;
