import React, { useEffect, useState } from 'react';
import * as API from "../../API/Index.js";

const PrivacyPolicy = () => {
    const [privacydata , setPrivacyData] = useState({});
    

    const privacyData = async()=>{
        try {
          
          const response = await API.getPrivacy()
          console.log('response'.response);
          setPrivacyData(response.data.data);
          
    
        } catch (error) {
          
        }
      }
    
    
    useEffect(() => {
        privacyData()
    }, []);

    return (
        <div>
            <section className="about innerpage">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2>{privacydata.title}</h2>
                            <div dangerouslySetInnerHTML={{ __html: privacydata.description }} />
                        </div>
                    </div>
                </div>    
            </section>        
        </div>
    );
}

export default PrivacyPolicy;
