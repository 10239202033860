import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as API from "../../API/Index.js";
import { IMG, NOIMG } from "../../API/constant.js";
import { BeatLoader } from "react-spinners";
import { Accordion } from "react-bootstrap-accordion";
const MovieList = () => {
  const [allData, setAllData] = useState([]);
  const [allGenre, setGenreData] = useState({ allCount: "", genreList: [] });
  const [filterData, setFilterData] = useState([]);
  const [dataArray, setdataArray] = useState([]);
  console.log("filterData", filterData);

  const handleChange = async (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setdataArray((prev) => [...prev, value]);
    } else {
      setdataArray((prev) => prev.filter((item) => item !== value));
    }
  };

  const allMovieData = async () => {
    setFilterData("");
    setdataArray("");
    try {
      const response = await API.getMovie();
      const genreResponse = await API.getGenre();
      console.log("genreResponse", genreResponse);

      setAllData(response.data.data);
      setGenreData({
        genreList: [genreResponse.data.data.genres],
        allCount: genreResponse.data.data.total_movie_count,
      });
    } catch (error) {}
  };

  useEffect(() => {
    const movielistUsingfilter = async () => {
      try {
        const reqObj = {
          value: dataArray,
          type: "genre",
        };
        const response = await API.getMoviebyFilter(reqObj);
        setFilterData(response.data.data);
        console.log("response", response);
      } catch (error) {}
    };
    if (dataArray.length > 0) {
      movielistUsingfilter();
    } else {
      allMovieData();
      setFilterData("");
    }
  }, [dataArray]);

  return (
    <>
      <section class="movie_list">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div className="col-sm-4 col-md-3">
              <div className="movieSidbar">
                <h6>
                  <Link to="#" onClick={allMovieData}>
                    All Movies <small class="badge ">{allGenre.allCount}</small>
                  </Link>
                </h6>
                <Accordion title="Genre" show={true}>
                  <ul className="showCheckBox">
                    {allGenre.genreList.length === 0
                      ? ""
                      : allGenre.genreList[0].map((item, index) => (
                          <li key={index}>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  id={item.id}
                                  name={item.genre}
                                  value={item.id}
                                  onChange={handleChange}
                                />
                                {item.genre}
                              </label>
                              <small class="badge rounded-pill bg-info">
                                {item.movie_count}
                              </small>
                            </div>
                          </li>
                        ))}
                  </ul>
                </Accordion>

                <Accordion title="Studio">
                  <ul className="showCheckBox">
                    <li>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="check19"
                            name="option1"
                            value="something"
                          />
                          Link come here
                        </label>
                        <small class="badge rounded-pill bg-info">120</small>
                      </div>
                    </li>
                    <li>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="check20"
                            name="option1"
                            value="something"
                          />
                          Link come here
                        </label>
                        <small class="badge rounded-pill bg-info">120</small>
                      </div>
                    </li>
                    <li>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="check21"
                            name="option1"
                            value="something"
                          />
                          Link come here
                        </label>
                        <small class="badge rounded-pill bg-info">120</small>
                      </div>
                    </li>
                    <li>
                      <div class="form-check">
                        <label class="form-check-label">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="check22"
                            name="option1"
                            value="something"
                          />
                          Link come here
                        </label>
                        <small class="badge rounded-pill bg-info">120</small>
                      </div>
                    </li>
                  </ul>
                </Accordion>

                <Accordion title="Director">
                  <ul>
                    <li>
                      <div class="form-check">
                        <Link to="#">
                          <label class="form-check-label">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="check15"
                              name="option1"
                              value="something"
                            />
                            Link come here
                          </label>
                          <small class="badge rounded-pill bg-info">120</small>
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div class="form-check">
                        <Link to="#">
                          <label class="form-check-label">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="check16"
                              name="option1"
                              value="something"
                            />
                            Link come here
                          </label>
                          <small class="badge rounded-pill bg-info">120</small>
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div class="form-check">
                        <Link to="#">
                          <label class="form-check-label">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="check17"
                              name="option1"
                              value="something"
                            />
                            Link come here
                          </label>
                          <small class="badge rounded-pill bg-info">120</small>
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div class="form-check">
                        <Link to="#">
                          <label class="form-check-label">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="check18"
                              name="option1"
                              value="something"
                            />
                            Link come here
                          </label>
                          <small class="badge rounded-pill bg-info" l>
                            120
                          </small>
                        </Link>
                      </div>
                    </li>
                  </ul>
                </Accordion>

                <Accordion title="Country">
                  <select className="form-select mt-2">
                    <option>Select Country</option>
                  </select>
                </Accordion>

                <Accordion title="Year">
                  <select className="form-select mt-2">
                    <option>Select Year</option>
                  </select>
                </Accordion>
              </div>
            </div>
            <div class="col-sm-8 col-md-9">
              <div class="secScroll">
                <div class="row">
                  <div class="col-12">
                    {/* <h2 class="site_heading text-center mb-4">Movies</h2> */}
                  </div>
                  {filterData.length === 0 ? (
                    <>
                      {allData === undefined || allData.length === 0 ? (
                        <div className="align-items-center d-flex justify-content-center">
                          <BeatLoader size={20} color="#fff" />
                        </div>
                      ) : (
                        allData.map((item, index) => (
                          <div
                            class="col-sm-6 col-md-3 col-lg-3 movies"
                            key={index}
                          >
                            <Link
                              state={{ dataMain: allData }}
                              to={`/movie-details/${item.slug}`}
                            >
                              <img
                                src={
                                  item.poster === null
                                    ? NOIMG
                                    : IMG + item.poster
                                }
                                alt=""
                                class="w-100"
                              />
                            </Link>
                            <h4 className="movieName">{item.title}</h4>
                          </div>
                        ))
                      )}
                    </>
                  ) : (
                    <>
                      {filterData === undefined || filterData.length === 0 ? (
                        <div className="align-items-center d-flex justify-content-center">
                          <BeatLoader size={20} color="#fff" />
                        </div>
                      ) : (
                        filterData.map((item, index) => (
                          <div
                            class="col-sm-6 col-md-3 col-lg-3 movies"
                            key={index}
                          >
                            <Link
                              state={{ dataMain: filterData }}
                              to={`/movie-details/${item.slug}`}
                            >
                              <img
                                src={
                                  item.poster === null
                                    ? NOIMG
                                    : IMG + item.poster
                                }
                                alt=""
                                class="w-100"
                              />
                            </Link>
                            <h4 className="movieName">{item.title}</h4>
                          </div>
                        ))
                      )}
                    </>
                  )}
                </div>

                <center>
                  <span>
                    <Link to="" className="btn btn-secondary2 mt-4">
                      Load More
                    </Link>
                  </span>
                </center>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MovieList;
