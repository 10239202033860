import React from "react";
import { Link } from "react-router-dom";
import { BNOIMG, IMG, NOIMG } from "../../API/constant";

const ProfileSection = ({ formData }) => {
  return (
    <>
      <section class="innerBanner">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-12 col-sm-11">
              <div class="innerBanner_img_wrap">
                <img
                  src={
                    formData.banner_img === "" || formData.banner_img === null
                      ? BNOIMG
                      : IMG + formData.banner_img
                  }
                  alt=""
                  class="w-100"
                />
              </div>
              <div class="users relative">
                <center>
                  <span class="main_User">
                    <img
                      src={
                        formData.profile_img === "" ||
                        formData.profile_img === null
                          ? NOIMG
                          : IMG + formData.profile_img
                      }
                      alt=""
                      class="w-100"
                    />
                    <span class="mail">
                      <i class="bi bi-envelope"></i>
                    </span>
                  </span>
                  <div class="clearfix"></div>
                  {/* <span class="joined">Joined Jan 21</span> */}
                  <h3 class="user_heading">{formData.name}</h3>
                  <p class="user_text">{formData.bio}</p>
                  {/* <span>
                    <Link to="/edit-profile" class="btn btn-secondary2">
                      My Account
                    </Link>
                  </span>
                  <span>
                    <Link to="/movie-upload" class="btn btn-outline-light">
                      My Movie
                    </Link>
                  </span> */}
                </center>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProfileSection;
