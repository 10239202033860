import React from "react";
import Slider from "./Slider";
import ReleaseSlide from "./ReleaseSlide";

const LatestRelease = ({ allData, movieWishListAdd }) => {
  return (
    <>
      <section class="text-center latest_release noBg">
        <div class="container">
          <div class="row">
            <div class="col-12 p-0">
              <h3 class="site_heading">
                Frame <span>Breakers</span>
              </h3>
              <ReleaseSlide
                movieWishListAdd={movieWishListAdd}
                allData={allData}
              />
              {/* <Slider allData={allData} /> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LatestRelease;
