import React from 'react';
import { blogIcon, calendarIcon, filmIcon, linkIcon, reviewIcon } from '../../AppUtilities';
import { Link } from 'react-router-dom';

const Forumdetails = () => {
  return (
    <div>
      <section class="forums-page movie_list forums-details">
        <div class="container-fluid">
            <ul class="details-menu d-flex">
                <li><Link to="#">Home</Link></li>
                <li><span>Questions</span></li>
            </ul>
            <div class="row">
                <div class="col-md-3">
                    <div className='forumsSidbar'>
                        <ul class="forums-menu">
                            <li><Link to="#"><span><img src={reviewIcon} alt="icon" /></span>User Reviews</Link></li>
                            <li><Link to="#"><span><img src={filmIcon} alt="icon" /></span>Film Clubs</Link></li>
                            <li><Link to="#"><span><img src={calendarIcon} alt="icon" /></span>Events Calendar</Link>
                            </li>
                            <li><Link to="#"><span><img src={blogIcon} alt="icon" /></span>Blog</Link></li>
                            <li><Link to="#"><span><img src={linkIcon} alt="icon" /></span>Social Media Links</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="forums-tab tab_main secScroll">
                        <div class="question-box">
                            <div class="question-inner">
                                <div class="user-img">
                                    <Link to="#"><img src="images/user.jpg" alt="user-img" class="img-fluid" /></Link>
                                    <ul class="question-vote">
                                        <li class="question-vote-up"><Link to="#"><i class="fa-solid fa-caret-up"></i></Link>
                                        </li>
                                        <li class="vote_result">134</li>
                                        <li class="question-vote-down"><Link to="#"><i
                                                    class="fa-solid fa-caret-down"></i></Link></li>
                                    </ul>
                                </div>

                                <div class="question-content">
                                    <div class="question-header">
                                        <Link to="#" class="user-name">James Wane</Link>
                                        <span class="badge bg-danger">Enlightened</span>
                                        <div class="post">
                                            <span class="post-date published">Asked: <Link to="#">April 19,
                                                    2023</Link></span>
                                            <span class="published">In:<Link to="#">Analytics</Link></span>
                                        </div>

                                    </div>
                                    <h5><Link to="#">What are your thoughts on Google Analytics vs other analytics
                                            platforms?</Link></h5>
                                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing
                                        elit.
                                        Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                        blanditiis
                                        corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                        Lorem ipsum dolor sit amet consectetur, adipisicing
                                        elit.
                                        Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                        blanditiis
                                        corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                    </p>
                                    <p>I saw him last night (correct)</p>
                                    <p>I see him last night (incorrect)</p>
                                    <p>But i think both has the same meaning and are understandable,</p>
                                    <p>Isn’t it?</p>
                                    <div class="question-tag">
                                        <Link to="#">English</Link>
                                        <Link to="#">Language</Link>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div class="question-footer d-flex justify-content-between">
                                <ul class="question-review">
                                    <li class="like"><Link to="#"><i class="fa-solid fa-thumbs-up"></i></Link></li>
                                    <li class="emoji">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span>172 Users</span>
                                    </li>
                                    <li class="comment-box">
                                        <Link to="#"><i class="fa-solid fa-comment"></i>1</Link>
                                    </li>
                                    <li class="comment-box">
                                        <Link to="#"><i class="fa-solid fa-comment"></i>2</Link>
                                    </li>
                                    <li>
                                        <i class="fa-regular fa-eye"></i>
                                        <span class="view">9k
                                            Views</span>
                                    </li>
                                </ul>
                                <div class="vote">
                                    <Link to="#" class="btn btn-secondary2">Answer</Link>
                                </div>
                            </div>
                            <hr />
                            <div class="question-bottom d-flex justify-content-between">
                                <span><Link to="#">Report</Link></span>
                                <ul class="d-flex">
                                    <li class="share"><Link to="#"><i class="fa-solid fa-share"></i></Link></li>
                                    <li class="facebook"><Link to="#"><i class="fa-brands fa-facebook"></i></Link></li>
                                    <li class="linkedin"><Link to="#"><i class="fa-brands fa-linkedin-in"></i></Link></li>
                                    <li class="whatsapp"><Link to="#"><i class="fa-brands fa-whatsapp"></i></Link></li>
                                    <li class="twitter"><Link to="#"><i class="fa-brands fa-x-twitter"></i></Link></li>
                                </ul>
                            </div>
                            <hr />
                            <form class="forums-details_tab">
                                <h6>Leave an answer</h6>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                                            <label class="form-check-label" for="flexCheckDefault">
                                                Default checkbox
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"
                                                checked/>
                                            <label class="form-check-label" for="flexCheckChecked">
                                                Checked checkbox
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-floating">
                                            <textarea class="form-control" placeholder="Leave a comment here"
                                                id="floatingTextarea2"></textarea>
                                            <label for="floatingTextarea2">Comments</label>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value=""
                                                id="flexCheckIndeterminate"/>
                                            <label class="form-check-label" for="flexCheckIndeterminate">
                                                Indeterminate checkbox
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <input type="text" class="form-control form-box" placeholder="Name"
                                            aria-label="First name"/>
                                    </div>
                                    <div class="col-md-6">
                                        <input type="email" class="form-control form-box" placeholder="Email"/>
                                    </div>
                                    <div class="col-12">
                                        <button type="submit" class="btn  btn-secondary2">submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="forums-tab tab_main forums-details_tab2">
                        <div class="d-flex justify-content-between">
                    <h4>3 Answers</h4>
                        <ul class="nav nav-tabs d-none d-lg-flex" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home"
                                    aria-selected="true">Oldest
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                                    aria-selected="false">Recent
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact"
                                    aria-selected="false">Random
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="pills-visited-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-visited" type="button" role="tab" aria-controls="pills-contact"
                                    aria-selected="false">Voted

                                </button>
                            </li>
                        </ul>
                        </div>
                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
                                aria-labelledby="pills-home-tab">
                                    
                                <div class="question-box">
                                    <div class="question-inner">
                                        <div class="user-img">
                                            <Link to="#"><img src="images/user.jpg" alt="user-img"
                                                    class="img-fluid" /></Link>
                                            <ul class="question-vote">
                                                <li class="question-vote-up"><Link to="#"><i
                                                            class="fa-solid fa-caret-up"></i></Link></li>
                                                <li class="vote_result">134</li>
                                                <li class="question-vote-down"><Link to="#"><i
                                                            class="fa-solid fa-caret-down"></i></Link></li>
                                            </ul>
                                        </div>

                                        <div class="question-content">
                                            <div class="question-header">
                                                <Link to="#" class="user-name">James Wane</Link>
                                                <span class="badge bg-danger">Enlightened</span>
                                                <div class="post">
                                                    <span class="post-date published">Asked: <Link to="#">April 19,
                                                            2023</Link></span>
                                                    <span class="published">In:<Link to="#">Analytics</Link></span>
                                                </div>

                                            </div>
                                            <h5><Link to="#">What are your thoughts on Google Analytics vs other analytics
                                                    platforms?</Link></h5>
                                            <p class="question-wrp">Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                                Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                            </p>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="question-footer d-flex justify-content-between">
                                        <ul class="question-review">
                                            <li class="like"><Link to="#"><i class="fa-solid fa-thumbs-up"></i></Link></li>
                                            <li class="emoji">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span>172 Users</span>
                                            </li>
                                            <li class="comment-box">
                                                <Link to="#"><i class="fa-solid fa-comment"></i>1</Link>
                                            </li>
                                            <li class="comment-box">
                                                <Link to="#"><i class="fa-solid fa-comment"></i>2</Link>
                                            </li>
                                            <li>
                                                <i class="fa-regular fa-eye"></i>
                                                <span class="view">9k
                                                    Views</span>
                                            </li>
                                        </ul>
                                        <div class="vote">
                                            <Link to="forums-details.php" class="btn btn-secondary2">Answer</Link>
                                        </div>
                                    </div>
                                </div>

                                <div class="question-box">
                                    <div class="question-inner">
                                        <div class="user-img">
                                            <Link to="#"><img src="images/user.jpg" alt="user-img"
                                                    class="img-fluid" /></Link>
                                            <ul class="question-vote">
                                                <li class="question-vote-up"><Link to="#"><i
                                                            class="fa-solid fa-caret-up"></i></Link></li>
                                                <li class="vote_result">134</li>
                                                <li class="question-vote-down"><Link to="#"><i
                                                            class="fa-solid fa-caret-down"></i></Link></li>
                                            </ul>
                                        </div>

                                        <div class="question-content">
                                            <div class="question-header">
                                                <Link to="#" class="user-name">James Wane</Link>
                                                <span class="badge bg-danger">Enlightened</span>
                                                <div class="post">
                                                    <span class="post-date published">Asked: <Link to="#">April 19,
                                                            2023</Link></span>
                                                    <span class="published">In:<Link to="#">Analytics</Link></span>
                                                </div>

                                            </div>
                                            <h5><Link to="#">What are your thoughts on Google Analytics vs other analytics
                                                    platforms?</Link></h5>
                                            <p class="question-wrp">Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                                Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                            </p>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="question-footer d-flex justify-content-between">
                                        <ul class="question-review">
                                            <li class="like"><Link to="#"><i class="fa-solid fa-thumbs-up"></i></Link></li>
                                            <li class="emoji">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span>172 Users</span>
                                            </li>
                                            <li class="comment-box">
                                                <Link to="#"><i class="fa-solid fa-comment"></i>1</Link>
                                            </li>
                                            <li class="comment-box">
                                                <Link to="#"><i class="fa-solid fa-comment"></i>2</Link>
                                            </li>
                                            <li>
                                                <i class="fa-regular fa-eye"></i>
                                                <span class="view">9k
                                                    Views</span>
                                            </li>
                                        </ul>
                                        <div class="vote">
                                            <Link to="#" class="btn btn-secondary2">Answer</Link>
                                        </div>
                                    </div>
                                </div>

                                <div class="question-box">
                                    <div class="question-inner">
                                        <div class="user-img">
                                            <Link to="#"><img src="images/user.jpg" alt="user-img"
                                                    class="img-fluid" /></Link>
                                            <ul class="question-vote">
                                                <li class="question-vote-up"><Link to="#"><i
                                                            class="fa-solid fa-caret-up"></i></Link></li>
                                                <li class="vote_result">134</li>
                                                <li class="question-vote-down"><Link to="#"><i
                                                            class="fa-solid fa-caret-down"></i></Link></li>
                                            </ul>
                                        </div>

                                        <div class="question-content">
                                            <div class="question-header">
                                                <Link to="#" class="user-name">James Wane</Link>
                                                <span class="badge bg-danger">Enlightened</span>
                                                <div class="post">
                                                    <span class="post-date published">Asked: <Link to="#">April 19,
                                                            2023</Link></span>
                                                    <span class="published">In:<Link to="#">Analytics</Link></span>
                                                </div>

                                            </div>
                                            <h5><Link to="#">What are your thoughts on Google Analytics vs other analytics
                                                    platforms?</Link></h5>
                                            <p class="question-wrp">Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                                Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                            </p>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="question-footer d-flex justify-content-between">
                                        <ul class="question-review">
                                            <li class="like"><Link to="#"><i class="fa-solid fa-thumbs-up"></i></Link></li>
                                            <li class="emoji">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span>172 Users</span>
                                            </li>
                                            <li class="comment-box">
                                                <Link to="#"><i class="fa-solid fa-comment"></i>1</Link>
                                            </li>
                                            <li class="comment-box">
                                                <Link to="#"><i class="fa-solid fa-comment"></i>2</Link>
                                            </li>
                                            <li>
                                                <i class="fa-regular fa-eye"></i>
                                                <span class="view">9k
                                                    Views</span>
                                            </li>
                                        </ul>
                                        <div class="vote">
                                            <Link to="#" class="btn btn-secondary2">Answer</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-profile" role="tabpanel"
                                aria-labelledby="pills-profile-tab">

                       
                                <div class="question-box">
                                    <div class="question-inner">
                                        <div class="user-img">
                                            <Link to="#"><img src="images/user.jpg" alt="user-img"
                                                    class="img-fluid" /></Link>
                                            <ul class="question-vote">
                                                <li class="question-vote-up"><Link to="#"><i
                                                            class="fa-solid fa-caret-up"></i></Link></li>
                                                <li class="vote_result">134</li>
                                                <li class="question-vote-down"><Link to="#"><i
                                                            class="fa-solid fa-caret-down"></i></Link></li>
                                            </ul>
                                        </div>

                                        <div class="question-content">
                                            <div class="question-header">
                                                <Link to="#" class="user-name">James Wane</Link>
                                                <span class="badge bg-danger">Enlightened</span>
                                                <div class="post">
                                                    <span class="post-date published">Asked: <Link to="#">April 19,
                                                            2023</Link></span>
                                                    <span class="published">In:<Link to="#">Analytics</Link></span>
                                                </div>

                                            </div>
                                            <h5><Link to="#">What are your thoughts on Google Analytics vs other analytics
                                                    platforms?</Link></h5>
                                            <p class="question-wrp">Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                                Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                            </p>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="question-footer d-flex justify-content-between">
                                        <ul class="question-review">
                                            <li class="like"><Link to="#"><i class="fa-solid fa-thumbs-up"></i></Link></li>
                                            <li class="emoji">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span>172 Users</span>
                                            </li>
                                            <li class="comment-box">
                                                <Link to="#"><i class="fa-solid fa-comment"></i>1</Link>
                                            </li>
                                            <li class="comment-box">
                                                <Link to="#"><i class="fa-solid fa-comment"></i>2</Link>
                                            </li>
                                            <li>
                                                <i class="fa-regular fa-eye"></i>
                                                <span class="view">9k
                                                    Views</span>
                                            </li>
                                        </ul>
                                        <div class="vote">
                                            <Link to="#" class="btn btn-secondary2">Answer</Link>
                                        </div>
                                    </div>
                                </div>

                                <div class="question-box">
                                    <div class="question-inner">
                                        <div class="user-img">
                                            <Link to="#"><img src="images/user.jpg" alt="user-img"
                                                    class="img-fluid" /></Link>
                                            <ul class="question-vote">
                                                <li class="question-vote-up"><Link to="#"><i
                                                            class="fa-solid fa-caret-up"></i></Link></li>
                                                <li class="vote_result">134</li>
                                                <li class="question-vote-down"><Link to="#"><i
                                                            class="fa-solid fa-caret-down"></i></Link></li>
                                            </ul>
                                        </div>

                                        <div class="question-content">
                                            <div class="question-header">
                                                <Link to="#" class="user-name">James Wane</Link>
                                                <span class="badge bg-danger">Enlightened</span>
                                                <div class="post">
                                                    <span class="post-date published">Asked: <Link to="#">April 19,
                                                            2023</Link></span>
                                                    <span class="published">In:<Link to="#">Analytics</Link></span>
                                                </div>

                                            </div>
                                            <h5><Link to="#">What are your thoughts on Google Analytics vs other analytics
                                                    platforms?</Link></h5>
                                            <p class="question-wrp">Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                                Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                            </p>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="question-footer d-flex justify-content-between">
                                        <ul class="question-review">
                                            <li class="like"><Link to="#"><i class="fa-solid fa-thumbs-up"></i></Link></li>
                                            <li class="emoji">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span>172 Users</span>
                                            </li>
                                            <li class="comment-box">
                                                <Link to="#"><i class="fa-solid fa-comment"></i>1</Link>
                                            </li>
                                            <li class="comment-box">
                                                <Link to="#"><i class="fa-solid fa-comment"></i>2</Link>
                                            </li>
                                            <li>
                                                <i class="fa-regular fa-eye"></i>
                                                <span class="view">9k
                                                    Views</span>
                                            </li>
                                        </ul>
                                        <div class="vote">
                                            <Link to="#" class="btn btn-secondary2">Answer</Link>
                                        </div>
                                    </div>
                                </div>

                                <div class="question-box">
                                    <div class="question-inner">
                                        <div class="user-img">
                                            <Link to="#"><img src="images/user.jpg" alt="user-img"
                                                    class="img-fluid" /></Link>
                                            <ul class="question-vote">
                                                <li class="question-vote-up"><Link to="#"><i
                                                            class="fa-solid fa-caret-up"></i></Link></li>
                                                <li class="vote_result">134</li>
                                                <li class="question-vote-down"><Link to="#"><i
                                                            class="fa-solid fa-caret-down"></i></Link></li>
                                            </ul>
                                        </div>

                                        <div class="question-content">
                                            <div class="question-header">
                                                <Link to="#" class="user-name">James Wane</Link>
                                                <span class="badge bg-danger">Enlightened</span>
                                                <div class="post">
                                                    <span class="post-date published">Asked: <Link to="#">April 19,
                                                            2023</Link></span>
                                                    <span class="published">In:<Link to="#">Analytics</Link></span>
                                                </div>

                                            </div>
                                            <h5><Link to="#">What are your thoughts on Google Analytics vs other analytics
                                                    platforms?</Link></h5>
                                            <p class="question-wrp">Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                                Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                            </p>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="question-footer d-flex justify-content-between">
                                        <ul class="question-review">
                                            <li class="like"><Link to="#"><i class="fa-solid fa-thumbs-up"></i></Link></li>
                                            <li class="emoji">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span>172 Users</span>
                                            </li>
                                            <li class="comment-box">
                                                <Link to="#"><i class="fa-solid fa-comment"></i>1</Link>
                                            </li>
                                            <li class="comment-box">
                                                <Link to="#"><i class="fa-solid fa-comment"></i>2</Link>
                                            </li>
                                            <li>
                                                <i class="fa-regular fa-eye"></i>
                                                <span class="view">9k
                                                    Views</span>
                                            </li>
                                        </ul>
                                        <div class="vote">
                                            <Link to="#" class="btn btn-secondary2">Answer</Link>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div class="tab-pane fade" id="pills-contact" role="tabpanel"
                                aria-labelledby="pills-contact-tab">

            
                                <div class="question-box">
                                    <div class="question-inner">
                                        <div class="user-img">
                                            <Link to="#"><img src="images/user.jpg" alt="user-img"
                                                    class="img-fluid" /></Link>
                                            <ul class="question-vote">
                                                <li class="question-vote-up"><Link to="#"><i
                                                            class="fa-solid fa-caret-up"></i></Link></li>
                                                <li class="vote_result">134</li>
                                                <li class="question-vote-down"><Link to="#"><i
                                                            class="fa-solid fa-caret-down"></i></Link></li>
                                            </ul>
                                        </div>

                                        <div class="question-content">
                                            <div class="question-header">
                                                <Link to="#" class="user-name">James Wane</Link>
                                                <span class="badge bg-danger">Enlightened</span>
                                                <div class="post">
                                                    <span class="post-date published">Asked: <Link to="#">April 19,
                                                            2023</Link></span>
                                                    <span class="published">In:<Link to="#">Analytics</Link></span>
                                                </div>

                                            </div>
                                            <h5><Link to="#">What are your thoughts on Google Analytics vs other analytics
                                                    platforms?</Link></h5>
                                            <p class="question-wrp">Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                                Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                            </p>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="question-footer d-flex justify-content-between">
                                        <ul class="question-review">
                                            <li class="like"><Link to="#"><i class="fa-solid fa-thumbs-up"></i></Link></li>
                                            <li class="emoji">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span>172 Users</span>
                                            </li>
                                            <li class="comment-box">
                                                <Link to="#"><i class="fa-solid fa-comment"></i>1</Link>
                                            </li>
                                            <li class="comment-box">
                                                <Link to="#"><i class="fa-solid fa-comment"></i>2</Link>
                                            </li>
                                            <li>
                                                <i class="fa-regular fa-eye"></i>
                                                <span class="view">9k
                                                    Views</span>
                                            </li>
                                        </ul>
                                        <div class="vote">
                                            <Link to="#" class="btn btn-secondary2">Answer</Link>
                                        </div>
                                    </div>
                                </div>

                                <div class="question-box">
                                    <div class="question-inner">
                                        <div class="user-img">
                                            <Link to="#"><img src="images/user.jpg" alt="user-img"
                                                    class="img-fluid" /></Link>
                                            <ul class="question-vote">
                                                <li class="question-vote-up"><Link to="#"><i
                                                            class="fa-solid fa-caret-up"></i></Link></li>
                                                <li class="vote_result">134</li>
                                                <li class="question-vote-down"><Link to="#"><i
                                                            class="fa-solid fa-caret-down"></i></Link></li>
                                            </ul>
                                        </div>

                                        <div class="question-content">
                                            <div class="question-header">
                                                <Link to="#" class="user-name">James Wane</Link>
                                                <span class="badge bg-danger">Enlightened</span>
                                                <div class="post">
                                                    <span class="post-date published">Asked: <Link to="#">April 19,
                                                            2023</Link></span>
                                                    <span class="published">In:<Link to="#">Analytics</Link></span>
                                                </div>

                                            </div>
                                            <h5><Link to="#">What are your thoughts on Google Analytics vs other analytics
                                                    platforms?</Link></h5>
                                            <p class="question-wrp">Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                                Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                            </p>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="question-footer d-flex justify-content-between">
                                        <ul class="question-review">
                                            <li class="like"><Link to="#"><i class="fa-solid fa-thumbs-up"></i></Link></li>
                                            <li class="emoji">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span>172 Users</span>
                                            </li>
                                            <li class="comment-box">
                                                <Link to="#"><i class="fa-solid fa-comment"></i>1</Link>
                                            </li>
                                            <li class="comment-box">
                                                <Link to="#"><i class="fa-solid fa-comment"></i>2</Link>
                                            </li>
                                            <li>
                                                <i class="fa-regular fa-eye"></i>
                                                <span class="view">9k
                                                    Views</span>
                                            </li>
                                        </ul>
                                        <div class="vote">
                                            <Link to="#" class="btn btn-secondary2">Answer</Link>
                                        </div>
                                    </div>
                                </div>

                                <div class="question-box">
                                    <div class="question-inner">
                                        <div class="user-img">
                                            <Link to="#"><img src="images/user.jpg" alt="user-img"
                                                    class="img-fluid" /></Link>
                                            <ul class="question-vote">
                                                <li class="question-vote-up"><Link to="#"><i
                                                            class="fa-solid fa-caret-up"></i></Link></li>
                                                <li class="vote_result">134</li>
                                                <li class="question-vote-down"><Link to="#"><i
                                                            class="fa-solid fa-caret-down"></i></Link></li>
                                            </ul>
                                        </div>

                                        <div class="question-content">
                                            <div class="question-header">
                                                <Link to="#" class="user-name">James Wane</Link>
                                                <span class="badge bg-danger">Enlightened</span>
                                                <div class="post">
                                                    <span class="post-date published">Asked: <Link to="#">April 19,
                                                            2023</Link></span>
                                                    <span class="published">In:<Link to="#">Analytics</Link></span>
                                                </div>

                                            </div>
                                            <h5><Link to="#">What are your thoughts on Google Analytics vs other analytics
                                                    platforms?</Link></h5>
                                            <p class="question-wrp">Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                                Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                            </p>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="question-footer d-flex justify-content-between">
                                        <ul class="question-review">
                                            <li class="like"><Link to="#"><i class="fa-solid fa-thumbs-up"></i></Link></li>
                                            <li class="emoji">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span>172 Users</span>
                                            </li>
                                            <li class="comment-box">
                                                <Link to="#"><i class="fa-solid fa-comment"></i>1</Link>
                                            </li>
                                            <li class="comment-box">
                                                <Link to="#"><i class="fa-solid fa-comment"></i>2</Link>
                                            </li>
                                            <li>
                                                <i class="fa-regular fa-eye"></i>
                                                <span class="view">9k
                                                    Views</span>
                                            </li>
                                        </ul>
                                        <div class="vote">
                                            <Link to="#" class="btn btn-secondary2">Answer</Link>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div class="tab-pane fade" id="pills-visited" role="tabpanel"
                                aria-labelledby="pills-contact-tab">

                                <div class="question-box">
                                    <div class="question-inner">
                                        <div class="user-img">
                                            <Link to="#"><img src="images/user.jpg" alt="user-img"
                                                    class="img-fluid" /></Link>
                                            <ul class="question-vote">
                                                <li class="question-vote-up"><Link to="#"><i
                                                            class="fa-solid fa-caret-up"></i></Link></li>
                                                <li class="vote_result">134</li>
                                                <li class="question-vote-down"><Link to="#"><i
                                                            class="fa-solid fa-caret-down"></i></Link></li>
                                            </ul>
                                        </div>

                                        <div class="question-content">
                                            <div class="question-header">
                                                <Link to="#" class="user-name">James Wane</Link>
                                                <span class="badge bg-danger">Enlightened</span>
                                                <div class="post">
                                                    <span class="post-date published">Asked: <Link to="#">April 19,
                                                            2023</Link></span>
                                                    <span class="published">In:<Link to="#">Analytics</Link></span>
                                                </div>

                                            </div>
                                            <h5><Link to="#">What are your thoughts on Google Analytics vs other analytics
                                                    platforms?</Link></h5>
                                            <p class="question-wrp">Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                                Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                            </p>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="question-footer d-flex justify-content-between">
                                        <ul class="question-review">
                                            <li class="like"><Link to="#"><i class="fa-solid fa-thumbs-up"></i></Link></li>
                                            <li class="emoji">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span>172 Users</span>
                                            </li>
                                            <li class="comment-box">
                                                <Link to="#"><i class="fa-solid fa-comment"></i>1</Link>
                                            </li>
                                            <li class="comment-box">
                                                <Link to="#"><i class="fa-solid fa-comment"></i>2</Link>
                                            </li>
                                            <li>
                                                <i class="fa-regular fa-eye"></i>
                                                <span class="view">9k
                                                    Views</span>
                                            </li>
                                        </ul>
                                        <div class="vote">
                                            <Link to="#" class="btn btn-secondary2">Answer</Link>
                                        </div>
                                    </div>
                                </div>

                                <div class="question-box">
                                    <div class="question-inner">
                                        <div class="user-img">
                                            <Link to="#"><img src="images/user.jpg" alt="user-img"
                                                    class="img-fluid" /></Link>
                                            <ul class="question-vote">
                                                <li class="question-vote-up"><Link to="#"><i
                                                            class="fa-solid fa-caret-up"></i></Link></li>
                                                <li class="vote_result">134</li>
                                                <li class="question-vote-down"><Link to="#"><i
                                                            class="fa-solid fa-caret-down"></i></Link></li>
                                            </ul>
                                        </div>

                                        <div class="question-content">
                                            <div class="question-header">
                                                <Link to="#" class="user-name">James Wane</Link>
                                                <span class="badge bg-danger">Enlightened</span>
                                                <div class="post">
                                                    <span class="post-date published">Asked: <Link to="#">April 19,
                                                            2023</Link></span>
                                                    <span class="published">In:<Link to="#">Analytics</Link></span>
                                                </div>

                                            </div>
                                            <h5><Link to="#">What are your thoughts on Google Analytics vs other analytics
                                                    platforms?</Link></h5>
                                            <p class="question-wrp">Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                                Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                            </p>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="question-footer d-flex justify-content-between">
                                        <ul class="question-review">
                                            <li class="like"><Link to="#"><i class="fa-solid fa-thumbs-up"></i></Link></li>
                                            <li class="emoji">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span>172 Users</span>
                                            </li>
                                            <li class="comment-box">
                                                <Link to="#"><i class="fa-solid fa-comment"></i>1</Link>
                                            </li>
                                            <li class="comment-box">
                                                <Link to="#"><i class="fa-solid fa-comment"></i>2</Link>
                                            </li>
                                            <li>
                                                <i class="fa-regular fa-eye"></i>
                                                <span class="view">9k
                                                    Views</span>
                                            </li>
                                        </ul>
                                        <div class="vote">
                                            <Link to="#" class="btn btn-secondary2">Answer</Link>
                                        </div>
                                    </div>
                                </div>

                                <div class="question-box">
                                    <div class="question-inner">
                                        <div class="user-img">
                                            <Link to="#"><img src="images/user.jpg" alt="user-img"
                                                    class="img-fluid" /></Link>
                                            <ul class="question-vote">
                                                <li class="question-vote-up"><Link to="#"><i
                                                            class="fa-solid fa-caret-up"></i></Link></li>
                                                <li class="vote_result">134</li>
                                                <li class="question-vote-down"><Link to="#"><i
                                                            class="fa-solid fa-caret-down"></i></Link></li>
                                            </ul>
                                        </div>

                                        <div class="question-content">
                                            <div class="question-header">
                                                <Link to="#" class="user-name">James Wane</Link>
                                                <span class="badge bg-danger">Enlightened</span>
                                                <div class="post">
                                                    <span class="post-date published">Asked: <Link to="#">April 19,
                                                            2023</Link></span>
                                                    <span class="published">In:<Link to="#">Analytics</Link></span>
                                                </div>

                                            </div>
                                            <h5><Link to="#">What are your thoughts on Google Analytics vs other analytics
                                                    platforms?</Link></h5>
                                            <p class="question-wrp">Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                                Lorem ipsum dolor sit amet consectetur, adipisicing
                                                elit.
                                                Debitis sed totam est eaque odit soluta dignissimos animi maiores,
                                                blanditiis
                                                corrupti. Delectus odit iure consequatur illum voluptates fugit hic cum ea.
                                            </p>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="question-footer d-flex justify-content-between">
                                        <ul class="question-review">
                                            <li class="like"><Link to="#"><i class="fa-solid fa-thumbs-up"></i></Link></li>
                                            <li class="emoji">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                                <span>172 Users</span>
                                            </li>
                                            <li class="comment-box">
                                                <Link to="#"><i class="fa-solid fa-comment"></i>1</Link>
                                            </li>
                                            <li class="comment-box">
                                                <Link to="#"><i class="fa-solid fa-comment"></i>2</Link>
                                            </li>
                                            <li>
                                                <i class="fa-regular fa-eye"></i>
                                                <span class="view">9k
                                                    Views</span>
                                            </li>
                                        </ul>
                                        <div class="vote">
                                            <Link to="#" class="btn btn-secondary2">Answer</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-md-3">
                    <div class="forums-sidebar">
                        <h4 class="text-center"><i class="fa-solid fa-chart-line"></i><span>Our Statistics</span></h4>
                        <hr />
                        <ul class="sidebar-cart">
                            <li>
                                <div class="d-flex justify-content-between card-item">
                                    <div class="card-icon"><i class="fa-solid fa-message"></i></div>
                                    <div class="card-text w-100 d-flex justify-content-between"> <span class="stats-text">
                                            Questions </span>
                                        <span class="stats-value"> 19 </span>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="d-flex justify-content-between card-item">
                                    <div class="card-icon"><i class="fa-solid fa-comments"></i></div>
                                    <div class="card-text w-100 d-flex justify-content-between"> <span class="stats-text">
                                            Answers </span>
                                        <span class="stats-value"> 39 </span>
                                    </div>
                                </div>
                            </li>

                            <li>
                                <div class="d-flex justify-content-between card-item">
                                    <div class="card-icon"><i class="fa-solid fa-award"></i></div>
                                    <div class="card-text w-100 d-flex justify-content-between"> <span class="stats-text">
                                            Best Answers </span>
                                        <span class="stats-value"> 5 </span>
                                    </div>
                                </div>
                            </li>

                            <li>
                                <div class="d-flex justify-content-between card-item">
                                    <div class="card-icon"><i class="fa-solid fa-user-group"></i></div>
                                    <div class="card-text w-100 d-flex justify-content-between"> <span class="stats-text">
                                            Users </span>
                                        <span class="stats-value"> 58 </span>
                                    </div>
                                </div>
                            </li>


                        </ul>
                    </div>
                    <div class="popular-question forums-sidebar">
                        <h4 class="text-center"><i class="fa-solid fa-folder"></i><span>
                                Popular Questions</span></h4>
                        <hr />
                        <ul class="question-item">
                            <li>
                                <Link to="#">
                                    <span class="author-img"><img src="images/user.jpg" alt="user-img"
                                            class="img-fluid"/></span>
                                    <div class="author-details">
                                        <span class="author-name">Sophie Taylor</span>
                                        <span class="content">Would it be possible to give a human artificial gills?</span>
                                        <div class="author-comment_box">
                                            <span><i class="fa-solid fa-comment"></i>2</span>
                                            <span><i class="fa-solid fa-comment"></i>4</span>
                                        </div>
                                    </div>
                                </Link>
                            </li>

                            <li>
                                <Link to="#">
                                    <span class="author-img"><img src="images/user.jpg" alt="user-img"
                                            class="img-fluid"/></span>
                                    <div class="author-details">
                                        <span class="author-name">Sophie Taylor</span>
                                        <span class="content">Would it be possible to give a human artificial gills?</span>
                                        <div class="author-comment_box">
                                            <span><i class="fa-solid fa-comment"></i>2</span>
                                            <span><i class="fa-solid fa-comment"></i>4</span>
                                        </div>
                                    </div>
                                </Link>
                            </li>

                            <li>
                                <Link to="#">
                                    <span class="author-img"><img src="images/user.jpg" alt="user-img"
                                            class="img-fluid"/></span>
                                    <div class="author-details">
                                        <span class="author-name">Sophie Taylor</span>
                                        <span class="content">Would it be possible to give a human artificial gills?</span>
                                        <div class="author-comment_box">
                                            <span><i class="fa-solid fa-comment"></i>2</span>
                                            <span><i class="fa-solid fa-comment"></i>4</span>
                                        </div>
                                    </div>
                                </Link>
                            </li>

                            <li>
                                <Link to="#">
                                    <span class="author-img"><img src="images/user.jpg" alt="user-img"
                                            class="img-fluid"/></span>
                                    <div class="author-details">
                                        <span class="author-name">Sophie Taylor</span>
                                        <span class="content">Would it be possible to give a human artificial gills?</span>
                                        <div class="author-comment_box">
                                            <span><i class="fa-solid fa-comment"></i>2</span>
                                            <span><i class="fa-solid fa-comment"></i>4</span>
                                        </div>
                                    </div>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div class="top-member forums-sidebar">
                        <h4 class="text-center"><i class="fa-solid fa-users"></i><span>
                                Top Members</span></h4>
                        <hr />
                        <ul class="question-item top-member_list">
                            <li>
                                <Link to="#">
                                    <span class="author-img"><img src="images/user.jpg" alt="user-img"
                                            class="img-fluid"/></span>
                                    <div class="author-details">
                                        <span class="author-name">Sophie Taylor</span>
                                        <div class="author-comment_box">
                                            <span>3
                                                Questions</span>
                                            <span>616
                                                Points</span>
                                        </div>
                                    </div>
                                </Link>
                            </li>

                            <li>
                                <Link to="#">
                                    <span class="author-img"><img src="images/user.jpg" alt="user-img"
                                            class="img-fluid"/></span>
                                    <div class="author-details">
                                        <span class="author-name">Sophie Taylor</span>
                                        <div class="author-comment_box">
                                            <span>3
                                                Questions</span>
                                            <span>616
                                                Points</span>
                                        </div>
                                    </div>
                                </Link>
                            </li>

                            <li>
                                <Link to="#">
                                    <span class="author-img"><img src="images/user.jpg" alt="user-img"
                                            class="img-fluid"/></span>
                                    <div class="author-details">
                                        <span class="author-name">Sophie Taylor</span>
                                        <div class="author-comment_box">
                                            <span>3
                                                Questions</span>
                                            <span>616
                                                Points</span>
                                        </div>
                                    </div>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div class="forums-tag top-member forums-sidebar">
                        <h4 class="text-center"><i class="fa-solid fa-tags"></i><span>
                                Trending Tags</span></h4>
                        <hr />
                        <p class="tag">
                            <Link to="#">Lorem</Link>
                            <Link to="#">Lorem</Link>
                            <Link to="#">Lorem</Link>
                            <Link to="#">Lorem</Link>
                            <Link to="#">Lorem</Link>
                            <Link to="#">Lorem</Link>
                            <Link to="#">Lorem</Link>
                            <Link to="#">Lorem</Link>
                            <Link to="#">Lorem</Link>
                            <Link to="#">Lorem</Link>
                            <Link to="#">Lorem</Link>
                            <Link to="#">Lorem</Link>
                            <Link to="#">Lorem</Link>
                            <Link to="#">Lorem</Link>
                            <Link to="#">Lorem</Link>
                            <Link to="#">Lorem</Link>
                            <Link to="#">Lorem</Link>
                            <Link to="#">Lorem</Link>
                            <Link to="#">Lorem</Link>
                            <Link to="#">Lorem</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  );
}

export default Forumdetails;
