import React, { useEffect, useState } from 'react';
import * as API from "../../API/Index.js";
import { latestSliders } from "../../AppUtilities";
import TeamSlide from './TeamSlide';

const About = () => {
  const [missionText, setMissionText] = useState({})

  const allMissintexts = async()=>{
    try {
      
      const response = await API.getMission()
      console.log('response'.response);
      setMissionText(response.data.data);
      

    } catch (error) {
      
    }
  }

  useEffect(() => {
    allMissintexts()
  }, []);

  return (
    <div>
        <section className="about innerpage" id='mission'>
          <div className="container">
              <div className="row">
                  <div className="col-12">
                      <img
                          src={latestSliders}
                          alt=""
                          class="aboutpic"
                      />
                      <h2>About Us</h2>
                      <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur.<br/><br/> At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.</p>

                      <div className="row">
                          <div className="col-sm-12 mt-4">
                              <h4><i class="fa-solid fa-angles-right"></i>{missionText.title}</h4>
                              <div dangerouslySetInnerHTML={{ __html: missionText.description }} />
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </section>

        <section className="team text-center pt-5" id='team'>
          <div className="container">
              <div className="row">
                  <div className="col-12 mb-4">
                      <h3 className="mb-4">Meet Our Team</h3>
                      <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.</p>
                  </div>

                  <div className="col-12 overflow-hidden">
                      <TeamSlide/>
                  </div>
              </div>
          </div>
        </section>

        <section className=" pb-5 " id='career'>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 text-center">
                <h2 className="mb-3">BECOME AN INSIDER</h2>
                <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>
                </div>

                <div className="col-12 col-sm-6 col-md-3 mt-4">
                    <a href="#" className="careerBox">
                        <h5>Reviews Manager</h5>
                        <p>At vero eos et accusamus et iusto odio dignissimos </p>
                        <a class="btn btn-secondary2" href="#">View Details</a>
                    </a>
                </div>
                <div className="col-12 col-sm-6 col-md-3 mt-4">
                    <a href="#" className="careerBox">
                        <h5>Reviews Manager</h5>
                        <p>At vero eos et accusamus et iusto odio dignissimos </p>
                        <a class="btn btn-secondary2" href="#">View Details</a>
                    </a>
                </div>
                <div className="col-12 col-sm-6 col-md-3 mt-4">
                    <a href="#" className="careerBox">
                        <h5>Reviews Manager</h5>
                        <p>At vero eos et accusamus et iusto odio dignissimos </p>
                        <a class="btn btn-secondary2" href="#">View Details</a>
                    </a>
                </div>
                <div className="col-12 col-sm-6 col-md-3 mt-4">
                    <a href="#" className="careerBox">
                        <h5>Reviews Manager</h5>
                        <p>At vero eos et accusamus et iusto odio dignissimos </p>
                        <a class="btn btn-secondary2" href="#">View Details</a>
                    </a>
                </div>
                <div className="col-12 col-sm-6 col-md-3 mt-4">
                    <a href="#" className="careerBox">
                        <h5>Reviews Manager</h5>
                        <p>At vero eos et accusamus et iusto odio dignissimos </p>
                        <a class="btn btn-secondary2" href="#">View Details</a>
                    </a>
                </div>
                <div className="col-12 col-sm-6 col-md-3 mt-4">
                    <a href="#" className="careerBox">
                        <h5>Reviews Manager</h5>
                        <p>At vero eos et accusamus et iusto odio dignissimos </p>
                        <a class="btn btn-secondary2" href="#">View Details</a>
                    </a>
                </div>
                <div className="col-12 col-sm-6 col-md-3 mt-4">
                    <a href="#" className="careerBox">
                        <h5>Reviews Manager</h5>
                        <p>At vero eos et accusamus et iusto odio dignissimos </p>
                        <a class="btn btn-secondary2" href="#">View Details</a>
                    </a>
                </div>
                <div className="col-12 col-sm-6 col-md-3 mt-4">
                    <a href="#" className="careerBox">
                        <h5>Reviews Manager</h5>
                        <p>At vero eos et accusamus et iusto odio dignissimos </p>
                        <a class="btn btn-secondary2" href="#">View Details</a>
                    </a>
                </div>
                
            </div>
        </div>
        </section>

        <section className="parallax" id="contact">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-sm-6 text-center">
                <h2 className="mb-3">GET STARTED</h2>
                <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>
                <a class="btn btn-secondary2" href="#">Call us</a>
                </div>
            </div>
        </div>
        </section>

        <section className="pt-5 pb-5 " id='press'>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 text-center">
                    <h2 className="mb-3">The Latest Press Release</h2>
                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 mt-4">
                        <a href="#" className="press">
                            <span>Tags</span>
                            <h4>blanditiis praesentium voluptatum</h4>
                            <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>
                            <hr/>
                            <small><i class="fa-solid fa-calendar-days"></i> 10th May 2024</small>
                        </a>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mt-4">
                        <a href="#" className="press">
                            <span>Tags</span>
                            <h4>blanditiis praesentium voluptatum</h4>
                            <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>
                            <hr/>
                            <small><i class="fa-solid fa-calendar-days"></i> 10th May 2024</small>
                        </a>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mt-4">
                        <a href="#" className="press">
                            <span>Tags</span>
                            <h4>blanditiis praesentium voluptatum</h4>
                            <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>
                            <hr/>
                            <small><i class="fa-solid fa-calendar-days"></i> 10th May 2024</small>
                        </a>
                    </div>
                    
                    
                </div>
            </div>
        </section>

    </div>
  );
}

export default About;
