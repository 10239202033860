import React, { useState } from "react";
import { logos } from "../AppUtilities";
import { Link, NavLink } from "react-router-dom";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
const Header = ({ setIsuser, isLogin }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  return (
    <>
      <header>
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-12 col-sm-11 d-flex justify-content-between align-items-center">
              <span class="logo">
                <Link to="/home">
                  <img src={logos} alt="" class="img-fluid" />
                </Link>
              </span>
              <nav>
                <div class="hamburger" id="hamburger-1" onClick={toggleDrawer}>
                  <span class="line"></span>
                  <span class="line"></span>
                  <span class="line"></span>
                </div>
                <ul>
                  <li>
                    <NavLink to="/home">Home</NavLink>
                  </li>
                  <li>
                    <NavLink to="/movie-list">
                      {" "}
                      Explore <i class="fa-solid fa-chevron-down"></i>
                    </NavLink>
                    <div class="megamenu">
                      <ul class="row">
                        <li class="col-sm-4 col-md-3">
                          <small></small>
                          <ul>
                            <li>
                              <NavLink to="#">
                                <h6>By Country</h6>
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="#">
                                <h6>By Director</h6>
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="#">
                                <h6>By Studio</h6>
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="#">
                                <h6>By Year</h6>
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="#">
                                <h6>New Releases</h6>
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="#">
                                <h6>Trending</h6>
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="#">
                                <h6>Recommended for You</h6>
                              </NavLink>
                            </li>
                          </ul>
                        </li>
                        <li class="col-sm-4 col-md-3">
                          <small>By Genre</small>
                          <ul>
                            <li>
                              <NavLink to="#">Action</NavLink>
                            </li>
                            <li>
                              <NavLink to="#">Drama</NavLink>
                            </li>
                            <li>
                              <NavLink to="#">Comedy</NavLink>
                            </li>
                            <li>
                              <NavLink to="#">Documentary</NavLink>
                            </li>
                            <li>
                              <NavLink to="#">Horror</NavLink>
                            </li>
                            <li>
                              <NavLink to="#">Sci-Fi</NavLink>
                            </li>
                            <li>
                              <NavLink to="#">Romance</NavLink>
                            </li>
                            <li>
                              <NavLink to="#">Thriller</NavLink>
                            </li>
                            <li>
                              <NavLink to="#">Animation</NavLink>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <NavLink to="javascript:void(0)">
                      {" "}
                      Live <i class="fa-solid fa-chevron-down"></i>
                    </NavLink>
                    <div class="megamenu">
                      <ul class="row">
                        <li class="col-sm-4 col-md-3">
                          <ul>
                            <li>
                              <NavLink to="#">
                                <h6>Upcoming Events</h6>
                              </NavLink>
                            </li>
                          </ul>
                        </li>
                        <li class="col-sm-4 col-md-3">
                          <ul>
                            <li>
                              <NavLink to="#">
                                <h6>Live Now</h6>
                              </NavLink>
                            </li>
                          </ul>
                        </li>
                        <li class="col-sm-4 col-md-3">
                          <ul>
                            <li>
                              <NavLink to="#">
                                <h6>Past Streams</h6>
                              </NavLink>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li>
                    <NavLink to="javascript:void(0)">
                      {" "}
                      Festivals <i class="fa-solid fa-chevron-down"></i>
                    </NavLink>
                    <div class="megamenu">
                      <ul class="row">
                        <li class="col-sm-4 col-md-3">
                          <ul>
                            <li>
                              <NavLink to="#">
                                <h6>Current Festivals</h6>
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="#">
                                <h6>Awards & Winners</h6>
                              </NavLink>
                            </li>
                          </ul>
                        </li>
                        <li class="col-sm-4 col-md-3">
                          <ul>
                            <li>
                              <NavLink to="#">
                                <h6>Past Festivals</h6>
                              </NavLink>
                            </li>
                          </ul>
                        </li>
                        <li class="col-sm-4 col-md-3">
                          <ul>
                            <li>
                              <NavLink to="#">
                                <h6>Submit Your Film</h6>
                              </NavLink>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <NavLink to="javascript:void(0)">
                      {" "}
                      Filmmakers <i class="fa-solid fa-chevron-down"></i>
                    </NavLink>

                    <div class="megamenu">
                      <ul class="row">
                        <li class="col-sm-4 col-md-3">
                          <ul>
                            <li>
                              <NavLink to="#">
                                <h6>Filmmaker Portal</h6>
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="#">
                                <h6>Resources & Tools</h6>
                              </NavLink>
                            </li>
                          </ul>
                        </li>
                        <li class="col-sm-4 col-md-3">
                          <ul>
                            <li>
                              <NavLink to="#">
                                <h6>Success Stories</h6>
                              </NavLink>
                            </li>
                          </ul>
                        </li>
                        <li class="col-sm-4 col-md-3">
                          <ul>
                            <li>
                              <NavLink to="#">
                                <h6>Interviews</h6>
                              </NavLink>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <NavLink to="/about">
                      {" "}
                      Community <i class="fa-solid fa-chevron-down"></i>
                    </NavLink>
                    <div class="megamenu">
                      <ul class="row">
                        <li class="col-sm-4 col-md-3">
                          <ul>
                            <li>
                              <NavLink to="#">
                                <h6>Discussions</h6>
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="#">
                                <h6>Watch Parties</h6>
                              </NavLink>
                            </li>
                          </ul>
                        </li>
                        <li class="col-sm-4 col-md-3">
                          <ul>
                            <li>
                              <NavLink to="#">
                                <h6>User Reviews</h6>
                              </NavLink>
                            </li>
                          </ul>
                        </li>
                        <li class="col-sm-4 col-md-3">
                          <ul>
                            <li>
                              <NavLink to="#">
                                <h6>Social Media</h6>
                              </NavLink>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <NavLink to="/about">
                      {" "}
                      About Us <i class="fa-solid fa-chevron-down"></i>
                    </NavLink>
                    <div class="megamenu">
                      <ul class="row">
                        <li class="col-sm-4 col-md-3">
                          <small>About Us</small>
                          <ul>
                            <li>
                              <a href="#mission">
                                <h6>Our Purpose</h6>
                              </a>
                            </li>
                            <li>
                              <a href="#team">
                                <h6>The Team</h6>
                              </a>
                            </li>
                            <li>
                              <a href="#career">
                                <h6>Careers</h6>
                              </a>
                            </li>
                            <li>
                              <a href="#press">
                                <h6>Press Releases</h6>
                              </a>
                            </li>
                            <li>
                              <a href="#contact">
                                <h6>Contact Us</h6>
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </li>
                  {/* <li>
                    <NavLink to="#">Plan <i class="fa-solid fa-chevron-down"></i></NavLink>

                    <div class="megamenu">
                      <ul class="row">
                        <li class="col-sm-4 col-md-3">
                            <small>Plan</small>
                            <ul>
                              <li><NavLink to="/plans"><h6>Subscription Plans</h6></NavLink></li>
                              <li><NavLink to="#"><h6>Pay-Per-View Options</h6></NavLink></li>
                              <li><NavLink to="#"><h6>Shop & Watch</h6></NavLink></li>
                              <li><NavLink to="#"><h6>Free Tickets</h6></NavLink></li>
                            </ul>
                        </li>
                      </ul>
                    </div>
                  </li> */}

                  {/* <li>
                    <NavLink to="/">Creators</NavLink>
                  </li> */}
                  {/* <li>
                    <NavLink to="/"> Shop <i class="fa-solid fa-chevron-down"></i></NavLink>
                    <div class="megamenu">
                      <ul class="row">
                        <li class="col-sm-4 col-md-3">
                            <small>Shop</small>
                            <ul>
                              <li><NavLink to="#"><h6>Merchandise</h6></NavLink></li>
                              <li><NavLink to="#"><h6>Posters & Art</h6></NavLink></li>
                              <li><NavLink to="#"><h6>DVDs & Blu-rays</h6></NavLink></li>
                              <li><NavLink to="#"><h6>Digital Downloads</h6></NavLink></li>
                              <li><NavLink to="#"><h6>Exclusive Content</h6></NavLink></li>
                            </ul>
                        </li>
                      </ul>
                    </div>
                  </li> */}

                  {/* <li>
                    <NavLink to="/Introduction">Series</NavLink>
                  </li>
                  <li>
                    <NavLink to="/dd">My List</NavLink>
                  </li> */}
                </ul>
              </nav>
              <ul class="infos">
                <li>
                  <NavLink to="#">
                    <i class="bi bi-search"></i>
                  </NavLink>
                </li>
                <li>
                  <Link
                    to="/creator/sign-up"
                    className="btn btn-secondary2 becomebtn"
                  >
                    Become a creator
                  </Link>
                </li>
                <li className="useRs">
                  <Link to={isLogin ? "/set-profile" : "/login"}>
                    <i class="bi bi-person-fill"></i>
                  </Link>
                  <ul className="cusTomDropdown">
                    <li>
                      <NavLink to="#">
                        <i class="fa-regular fa-user"></i> Profile
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="#">
                        <i class="fa-solid fa-gear"></i> Settings
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="#">
                        <i class="fa-solid fa-eye"></i> Watchlist
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="#">
                        <i class="fa-solid fa-list-check"></i> Viewing History
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="#">
                        <i class="fa-regular fa-bell"></i> Subscription Details
                      </NavLink>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        className="bla bla bla"
      >
        <div>
          <div className="d-flex justify-content-end">
            <span onClick={() => setIsOpen(false)}>crose</span>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default Header;
