import React from "react";
import { NavLink, useNavigate } from "react-router-dom";

const SideBar = ({ setIsLogin, formData }) => {
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("_tokenCode");
    localStorage.removeItem("isLogin");
    localStorage.removeItem("_userId");
    setIsLogin(localStorage.removeItem("isLogin"));
    if (localStorage.getItem("isLogin") === null) {
      navigate("/login");
    }
  };

  return (
    <>
      <ul class="nav nav-tabs flex-column mt-4" id="myTab" role="tablist">
        {formData.user_type === "user" ? (
          ""
        ) : (
          <li class="nav-item" role="presentation">
            <NavLink className="nav-link" to="/creator/movie-list">
              <i class="bi bi-camera-reels-fill"></i>&nbsp; My Movie
            </NavLink>
          </li>
        )}

        <li class="nav-item" role="presentation">
          <NavLink className="nav-link" to="/set-profile">
            <i class="fa-solid fa-user"></i>&nbsp; Edit profile
          </NavLink>
        </li>
        {formData.user_type === "user" ? (
          ""
        ) : (
          <li class="nav-item" role="presentation">
            <NavLink className="nav-link" to="/movie-upload">
              <i class="fa-solid fa-circle-play"></i> Movie Upload
            </NavLink>
          </li>
        )}

        {/* <li class="nav-item" role="presentation">
          <NavLink to="/#" className="nav-link">
            <i class="fa-solid fa-file-video"></i>
            Series
          </NavLink>
        </li> */}

        {formData.user_type === "user" ? (
          ""
        ) : (
          <li class="nav-item" role="presentation">
            <NavLink className="nav-link" to="/award-list">
              <i class="fa-solid fa-award"></i>
              Awards
            </NavLink>
          </li>
        )}

        <div className="logout">
          <button onClick={logout} className="logouts btn btn-secondary2">
            Logout
          </button>
        </div>
      </ul>
    </>
  );
};

export default SideBar;
