import React, { useState } from "react";
import ProfileSection from "./ProfileSection";
import SideBar from "../UserDashboard/SideBar";
import { BeatLoader } from "react-spinners";
import * as API from "../../API/Index.js";
import { MESSAGE } from "../../schemas/Validation.js";
import { useNavigate } from "react-router";
const initialValues = {
  name: "",
  description: "",
};
const AddAward = ({ data, setIsLogin }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState("");
  const [isLoder, setIsLoder] = useState(false);
  const [awardImg, setAwardImg] = useState("");
  const handalerChanges = async (e) => {
    const { name, value } = e.target;
    setIsLoder(false);
    setFormData({ ...formData, [name]: value });
  };
  const uploadVideo = async () => {
    setIsLoder(true);
    const header = localStorage.getItem("_tokenCode");
    try {
      const reqObj = {
        name: formData.name,
        file: awardImg,
        user_id: localStorage.getItem("_userId"),
        description: formData.description,
      };
      console.log("reqObj", reqObj);
      const response = await API.addAwards(reqObj, header);
      console.log("response", response);
      if (response.data.success === 1) {
        navigate("/award-list");
        setIsLoder(false);
        MESSAGE(response.data.message, 1);
      }
    } catch (error) {}
  };

  return (
    <>
      <ProfileSection formData={data} />
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="jj">
            <div class="tab_main editProfile">
              <div class="row justify-content-center g-0">
                <div class="col-sm-12 col-md-12 row">
                  <div className="col-md-3">
                    <SideBar formData={data} setIsLogin={setIsLogin} />
                  </div>
                  <div className="col-md-9">
                    <div className="tab-content">
                      <div class="row row--form">
                        <div className="form__content">
                          <div class="col-12">
                            <input
                              type="text"
                              class="form__input"
                              placeholder="Title"
                              name="name"
                              value={formData.name}
                              onChange={handalerChanges}
                            />
                          </div>
                          <div class="col-12">
                            <input
                              type="file"
                              class="form__input"
                              placeholder="Title"
                              name="movie_name"
                              // value={formData.movie_name}
                              onChange={(e) => setAwardImg(e.target.files[0])}
                            />
                          </div>
                          {/* <div class="col-12">
                            <textarea
                              class="form-control "
                              rows="5"
                              cols="10"
                              style={{ height: 100 }}
                              placeholder="Description"
                              value={formData.description}
                              name="description"
                              onChange={handalerChanges}
                            ></textarea>
                          </div> */}
                          <div class="col-12">
                            <div class="row row--form">
                              <div class="col-12">
                                {isLoder ? (
                                  <button
                                    type="button"
                                    class="btn btn btn-secondary2 mt-3"
                                  >
                                    <BeatLoader color="#fff" />
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    onClick={uploadVideo}
                                    class="btn btn btn-secondary2 mt-3"
                                  >
                                    Submit
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAward;
