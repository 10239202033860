import React from 'react';

const Plans = () => {
  return (
    <>
        <section class="movie_list innerpage pricingPaid">
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-md-11 text-center">
                        <h2>Choose the plan that’s right for you</h2>

                        <div class="row mt-5">
                        <div class="col-xs-12 col-sm-6 col-md-3">
                            <div class="panel pricingGrid pricingBasic">
                            <div class="panel-heading">
                                <h3>Basic</h3></div>
                            <div class="panel-body">
                                <p>Features Include</p>
                                <ul>
                                <li><strong>2</strong> Users</li>
                                <li><strong>2</strong> Service Workflows</li>
                                <li>Unlimited Invoice</li>
                                </ul>
                            </div>
                            <div class="panel-footer">
                                <div class="pricingRate">
                                <div class="priceAnnual"><span class="price"><sup>$</sup>50<small>/month</small></span> <span class="pricePlans">Save $120 Annually</span></div>
                                <div class="priceMonthly"><span class="price priceMonthly"><sup>$</sup>60<small>/month</small></span> <span class="pricePlans">$50 when paid annually</span></div>
                                </div>
                                <div class="pricingButton"><a href="register?plan=basic_annual" class="btn btnAnnually btnPrimary">Get Started</a> <a href="register?plan=basic_monthly" class="btn btnMonthly btnPrimary">Get Started</a></div>
                            </div>
                        </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3">
                            <div class="panel pricingGrid pricingPlus">
                            <div class="panel-heading">
                                <h3>Plus</h3>
                                <label>Most Popular</label>
                            </div>
                            <div class="panel-body">
                                <p>Features Include</p>
                                <ul>
                                <li><strong>5</strong> Users</li>
                                <li><strong>4</strong> Service Workflows</li>
                                <li>Unlimited Invoice</li>
                                </ul>
                            </div>
                            <div class="panel-footer">
                                <div class="pricingRate">
                                <div class="priceAnnual"><span class="price"><sup>$</sup>100<small>/month</small></span> <span class="pricePlans">Save $240 Annually</span></div>
                                <div class="priceMonthly"><span class="price priceMonthly"><sup>$</sup>120<small>/month</small></span> <span class="pricePlans">$100 when paid annually</span></div>
                                </div>
                                <div class="pricingButton"><a href="register?plan=plus_annual" class="btn btnAnnually btnOrange">Get Started</a> <a href="register?plan=plus_monthly" class="btn btnMonthly btnOrange">Get Started</a></div>
                            </div>
                        </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3">
                            <div class="panel pricingGrid pricingPremium">
                            <div class="panel-heading">
                                <h3>Premium</h3></div>
                            <div class="panel-body">
                                <p>Features Include</p>
                                <ul>
                                <li><strong>10</strong> Users</li>
                                <li><strong>8</strong> Service Workflows</li>
                                <li>Unlimited Invoice</li>
                                </ul>
                            </div>
                            <div class="panel-footer">
                                <div class="pricingRate">
                                <div class="priceAnnual"><span class="price"><sup>$</sup>150<small>/month</small></span> <span class="pricePlans">Save $360 Annually</span></div>
                                <div class="priceMonthly"><span class="price priceMonthly"><sup>$</sup>180<small>/month</small></span> <span class="pricePlans">$150 when paid annually</span></div>
                                </div>
                                <div class="pricingButton"><a href="register?plan=premium_annual" class="btn btnAnnually btnPurple">Get Started</a> <a href="register?plan=premium_monthly" class="btn btnMonthly btnPurple">Get Started</a></div>
                            </div>
                        </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3">
                            <div class="panel pricingGrid pricingEnterprise">
                            <div class="panel-heading">
                                <h3>Enterprise</h3></div>
                            <div class="panel-body">
                                <p>Features Include</p>
                                <ul>
                                <li><strong>20</strong> Users</li>
                                <li>Unlimited Service Workflows</li>
                                <li>Unlimited Invoice</li>
                                </ul>
                            </div>
                            <div class="panel-footer">
                                <div class="pricingRate">
                                <div class="priceAnnual enterpriseAnnually">
                                    <div class="price"><sup>$</sup> <span>375</span> <small>/month</small></div>
                                    <div class="pricePlans">Save $900 Annually</div>
                                </div>
                                <div class="priceMonthly enterpriseMonthly">
                                    <div class="price priceMonthly"><sup>$</sup> <span>450</span> <small>/month</small></div>
                                    <div class="pricePlans">$375 when paid annually</div>
                                </div>
                                </div>
                                <div class="pricingButton"><a href="register?plan=enterprise_annual&amp;users_count=15" class="btn btnAnnually btnGreen">Get Started</a> <a href="register?plan=enterprise_monthly&amp;users_count=15" class="btn btnMonthly btnGreen">Get Started</a></div>
                            </div>
                        </div>
                        </div>
                        </div>
                    </div>
                    
                    
                </div>
            </div>
        </section>
    </>
  );
}

export default Plans;
