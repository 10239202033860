import React from 'react';
import { bannerMovies } from '../../AppUtilities';

const ProductList = () => {
  return (
    <>
      <section class="movie_list shop">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-sm-4 col-md-3 shopLeft">
                <div className='forumsSidbar'>
                    <h2 className='mb-5'>Shop</h2>
                    <h6>Shop By Categories</h6>
                    <hr/>
                    <ul>
                        <li>
                            <div class="form-check">
                                <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" id="check1" name="option1" value="something"/>
                                Merchandise</label>
                            </div>
                        </li>
                        <li>
                            <div class="form-check">
                                <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" id="check2" name="option2" value="something"/>
                                Posters & Art</label>
                            </div>
                        </li>
                        <li>
                            <div class="form-check">
                                <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" id="check3" name="option3" value="something"/>
                                DVDs & Blu-rays</label>
                            </div>
                        </li>
                        <li>
                            <div class="form-check">
                                <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" id="check4" name="option4" value="something"/>
                                Digital Downloads</label>
                            </div>
                        </li>
                    </ul>

                    <h6>Filter By</h6>
                    <hr/>
                    <p class="mt-3 mb-1"> <strong>Availability</strong></p>
                    <ul>
                        <li>
                            <div class="form-check">
                                <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" id="check5" name="option5" value="something"/>
                                In stock (12)</label>
                            </div>
                        </li>
                        <li>
                            <div class="form-check">
                                <label class="form-check-label">
                                <input class="form-check-input" type="checkbox" id="check6" name="option6" value="something"/>
                                Out of stock (1)</label>
                            </div>
                        </li>
                    </ul>
                    <h6>Price</h6>
                    <hr/>
                    <div class="price d-flex mt-4">
                        <span>
                            $ <input type="text" class="form-control" placeholder="Form"/>
                        </span>
                        <span>
                            $ <input type="text" class="form-control" placeholder="To"/>
                        </span>
                    </div>
                    <button class="btn btn-secondary2 mt-4 mb-4">Submit</button>
                </div>
                </div>

                <div class="col-sm-8 col-md-8 shopRight">
                    <div className="secScroll">
                        <div class="d-flex justify-content-between align-items-center">
                            <select name="" id="" class="form-select">
                                <option value="">Best Selling</option>
                                <option value="">Feaured</option>
                                <option value="">Price, High to low</option>
                                <option value="">Price, low to high</option>
                            </select>
                            <h6>9 items</h6>
                        </div>
                        <div class="row">
                            <div class="col-sm-6 col-md-6 col-lg-4">
                                <div class="shopBox mt-4">
                                    <img src={bannerMovies} alt="poster" class="w-100"/>
                                    <div class="shopContent">
                                        <ul class="stars">
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                        </ul>
                                        <h4>Poster Title</h4>
                                        <span class="price">$25.00</span> <span class="price cut">$18.00</span>
                                        <ul class="floatOption d-flex">
                                            <li><a href="#"><i class="bi bi-heart-fill"></i></a></li>
                                            <li><a href="#"><i class="bi bi-eye-fill"></i></a></li>
                                            <li><a href="#"><i class="bi bi-bag-fill"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4">
                                <div class="shopBox mt-4">
                                    <img src={bannerMovies} alt="poster" class="w-100"/>
                                    <div class="shopContent">
                                        <ul class="stars">
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                        </ul>
                                        <h4>Poster Title</h4>
                                        <span class="price">$25.00</span> <span class="price cut">$18.00</span>
                                        <ul class="floatOption d-flex">
                                            <li><a href="#"><i class="bi bi-heart-fill"></i></a></li>
                                            <li><a href="#"><i class="bi bi-eye-fill"></i></a></li>
                                            <li><a href="#"><i class="bi bi-bag-fill"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4">
                                <div class="shopBox mt-4">
                                <img src={bannerMovies} alt="poster" class="w-100"/>
                                    <div class="shopContent">
                                        <ul class="stars">
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                        </ul>
                                        <h4>Poster Title</h4>
                                        <span class="price">$25.00</span> <span class="price cut">$18.00</span>
                                        <ul class="floatOption d-flex">
                                            <li><a href="#"><i class="bi bi-heart-fill"></i></a></li>
                                            <li><a href="#"><i class="bi bi-eye-fill"></i></a></li>
                                            <li><a href="#"><i class="bi bi-bag-fill"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4">
                                <div class="shopBox mt-4">
                                <img src={bannerMovies} alt="poster" class="w-100"/>
                                    <div class="shopContent">
                                        <ul class="stars">
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                        </ul>
                                        <h4>Poster Title</h4>
                                        <span class="price">$25.00</span> <span class="price cut">$18.00</span>
                                        <ul class="floatOption d-flex">
                                            <li><a href="#"><i class="bi bi-heart-fill"></i></a></li>
                                            <li><a href="#"><i class="bi bi-eye-fill"></i></a></li>
                                            <li><a href="#"><i class="bi bi-bag-fill"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4">
                                <div class="shopBox mt-4">
                                <img src={bannerMovies} alt="poster" class="w-100"/>
                                    <div class="shopContent">
                                        <ul class="stars">
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                        </ul>
                                        <h4>Poster Title</h4>
                                        <span class="price">$25.00</span> <span class="price cut">$18.00</span>
                                        <ul class="floatOption d-flex">
                                            <li><a href="#"><i class="bi bi-heart-fill"></i></a></li>
                                            <li><a href="#"><i class="bi bi-eye-fill"></i></a></li>
                                            <li><a href="#"><i class="bi bi-bag-fill"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4">
                                <div class="shopBox mt-4">
                                <img src={bannerMovies} alt="poster" class="w-100"/>
                                    <div class="shopContent">
                                        <ul class="stars">
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                        </ul>
                                        <h4>Poster Title</h4>
                                        <span class="price">$25.00</span> <span class="price cut">$18.00</span>
                                        <ul class="floatOption d-flex">
                                            <li><a href="#"><i class="bi bi-heart-fill"></i></a></li>
                                            <li><a href="#"><i class="bi bi-eye-fill"></i></a></li>
                                            <li><a href="#"><i class="bi bi-bag-fill"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4">
                                <div class="shopBox mt-4">
                                <img src={bannerMovies} alt="poster" class="w-100"/>
                                    <div class="shopContent">
                                        <ul class="stars">
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                        </ul>
                                        <h4>Poster Title</h4>
                                        <span class="price">$25.00</span> <span class="price cut">$18.00</span>
                                        <ul class="floatOption d-flex">
                                            <li><a href="#"><i class="bi bi-heart-fill"></i></a></li>
                                            <li><a href="#"><i class="bi bi-eye-fill"></i></a></li>
                                            <li><a href="#"><i class="bi bi-bag-fill"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4">
                                <div class="shopBox mt-4">
                                <img src={bannerMovies} alt="poster" class="w-100"/>
                                    <div class="shopContent">
                                        <ul class="stars">
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                        </ul>
                                        <h4>Poster Title</h4>
                                        <span class="price">$25.00</span> <span class="price cut">$18.00</span>
                                        <ul class="floatOption d-flex">
                                            <li><a href="#"><i class="bi bi-heart-fill"></i></a></li>
                                            <li><a href="#"><i class="bi bi-eye-fill"></i></a></li>
                                            <li><a href="#"><i class="bi bi-bag-fill"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4">
                                <div class="shopBox mt-4">
                                <img src={bannerMovies} alt="poster" class="w-100"/>
                                    <div class="shopContent">
                                        <ul class="stars">
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                            <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                        </ul>
                                        <h4>Poster Title</h4>
                                        <span class="price">$25.00</span> <span class="price cut">$18.00</span>
                                        <ul class="floatOption d-flex">
                                            <li><a href="#"><i class="bi bi-heart-fill"></i></a></li>
                                            <li><a href="#"><i class="bi bi-eye-fill"></i></a></li>
                                            <li><a href="#"><i class="bi bi-bag-fill"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                                <ul class="pagination pagination-sm justify-content-center mt-5">
                                    <li class="page-item disabled"><a class="page-link" href="#">Previous</a></li>
                                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                                    <li class="page-item"><a class="page-link" href="#">Next</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  );
}

export default ProductList;
