import React from 'react';
import { users } from '../../AppUtilities';

const UserReview = () => {
  return (
    <div>
        <section class="movie_list user-review_page">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12">
                        <h2 class="site_heading text-center">User Reviews</h2>
                    </div>
                    <div class="col-md-6 col-sm-6">
                        <div class="review-box">
                            <img src={users} alt="review-img" class="img-fluid" />
                            <h3>Lorem ipsum</h3>
                            <small>Ceo,Agency</small>
                            <p><span class="coma"><i class="fa-solid fa-quote-left"></i></span>Lorem ipsum dolor sit amet
                                consectetur
                                adipisicing elit. Et obcaecati distinctio itaque minus
                                iure, laudantium nostrum reiciendis non adipisci fugiat maxime minima, enim aperiam optio
                                facere! Libero a dignissimos aliquam?<span class="coma2"><i
                                        class="fa-solid fa-quote-right"></i></span></p>
                            <ul class="stars justify-content-center">
                                <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6">
                        <div class="review-box">
                            <img src={users} alt="review-img" class="img-fluid" />
                            <h3>Lorem ipsum</h3>
                            <small>Ceo,Agency</small>
                            <p><span class="coma"><i class="fa-solid fa-quote-left"></i></span>Lorem ipsum dolor sit amet
                                consectetur
                                adipisicing elit. Et obcaecati distinctio itaque minus
                                iure, laudantium nostrum reiciendis non adipisci fugiat maxime minima, enim aperiam optio
                                facere! Libero a dignissimos aliquam?<span class="coma2"><i
                                        class="fa-solid fa-quote-right"></i></span></p>
                            <ul class="stars justify-content-center">
                                <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6">
                        <div class="review-box">
                            <img src={users} alt="review-img" class="img-fluid" />
                            <h3>Lorem ipsum</h3>
                            <small>Ceo,Agency</small>
                            <p><span class="coma"><i class="fa-solid fa-quote-left"></i></span>Lorem ipsum dolor sit amet
                                consectetur
                                adipisicing elit. Et obcaecati distinctio itaque minus
                                iure, laudantium nostrum reiciendis non adipisci fugiat maxime minima, enim aperiam optio
                                facere! Libero a dignissimos aliquam?<span class="coma2"><i
                                        class="fa-solid fa-quote-right"></i></span></p>
                            <ul class="stars justify-content-center">
                                <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6">
                        <div class="review-box">
                            <img src={users} alt="review-img" class="img-fluid" />
                            <h3>Lorem ipsum</h3>
                            <small>Ceo,Agency</small>
                            <p><span class="coma"><i class="fa-solid fa-quote-left"></i></span>Lorem ipsum dolor sit amet
                                consectetur
                                adipisicing elit. Et obcaecati distinctio itaque minus
                                iure, laudantium nostrum reiciendis non adipisci fugiat maxime minima, enim aperiam optio
                                facere! Libero a dignissimos aliquam?<span class="coma2"><i
                                        class="fa-solid fa-quote-right"></i></span></p>
                            <ul class="stars justify-content-center">
                                <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                                <li><a href="#"><i class="fa-solid fa-star"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  );
}

export default UserReview;
