import React, { useEffect, useState } from "react";
import VideoPlayer from "../components/Video/VideoPlayer";
import { useLocation, useNavigate } from "react-router";
import * as API from "../API/Index.js";
import { Link } from "react-router-dom";
const AllVideoPlayer = () => {
  const location = useLocation();
  const [videoFormat, setVideoFormat] = useState("");
  const [videoType, setVideoType] = useState(2);
  const navigate = useNavigate();

  //   ?****** MOVIE OR TRAILER DATA SHOW
  const movieShow = async () => {
    const header = localStorage.getItem("_tokenCode");
    try {
      const reqObj = {
        type: location.state.videoType === 1 ? "movie" : "trailer",
        id: location.state.id,
      };
      //  console.log("reqObj", reqObj);
      const response = await API.getMovieDetailsS3(reqObj, header);
      setVideoFormat(response.data.fileUrl);
      console.log("response", response);
    } catch (error) {}
    if (localStorage.getItem("isLogin") === null) {
      navigate("/login");
    }
    // setVideoType(data);
    //setShowMove(!showMove);
  };

  useEffect(() => {
    movieShow();
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Link to="/movie-list" className="backButton moveiwatch">
        <i class="bi bi-arrow-left"></i>
      </Link>
      <VideoPlayer videoType={videoType} videoFormat={videoFormat} />
    </>
  );
};

export default AllVideoPlayer;
